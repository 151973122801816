import React from "react";
import {
  empoweringresources,
  givingbacktosociety,
  happycustomer,
} from "../assets";
import { HomeIndustrySectionEntry } from "../components/HomeIndustrySectionEntry";

export function HomeIndustrySection() {
  return (
    <div className="home__industry">
      <div className="home__industry__heading">
        Creating an <span>Industry</span>
      </div>
      <div className="home__industry__content">
        <HomeIndustrySectionEntry
          img={empoweringresources}
          alt="empoweringresources"
          title={
            <>
              Empowering <span>Resources</span>
            </>
          }
          content={[
            "Enhanced Employment Rights ",
            "Systematic Compensation Structures ",
            "Job Stability and Security",
          ]}
        />
        <HomeIndustrySectionEntry
          img={givingbacktosociety}
          alt="givingbacktosociety"
          title={
            <>
              Giving Back <span>To The Society</span>
            </>
          }
          content={["Streamlined Processes ", "Abundant Job Opportunities"]}
        />
        <HomeIndustrySectionEntry
          img={happycustomer}
          alt="happycustomer"
          title={
            <>
              Happy <span>Customers</span>
            </>
          }
          content={[
            "Skilled workforce",
            "Enhanced Consumer Entitlements",
            "Seamless Resources Availability",
          ]}
        />
      </div>
    </div>
  );
}

import React from "react";
import { Fade, Zoom } from "react-reveal";
import { benifitsjumbotron } from "../assets";

export function BenifitsJumbotron() {
  return (
    <div className="about__jumbotron">
      <img
        src={benifitsjumbotron}
        alt="benifitsjumbotron"
        className="about__jumbotron__img"
      />
      <Fade>
        <div className="about__jumbotron__overlay">
          <div className="about__jumbotron__overlay__heading">
            Customers Benefits
          </div>
          <div className="about__jumbotron__overlay__info">
            Our customers are paramount to us, treated as more than just
            clients—they are an extension of our family. We wholeheartedly
            commit to providing unwavering support to make their journey with us
            not only memorable but also exceptionally comfortable.
          </div>
        </div>
      </Fade>
    </div>
  );
}

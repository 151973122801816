import React from "react";

export function BenifitsAppCard() {
  return (
    <div className="readymaid_app_card">
      <div className="readymaid_app_card_left">
        <div className="readymaid_app_card_left_para">
          Our aim is to take the hassle of managing Human Resources away from
          you in a unique, technologically advanced & convenient way so you can
          dedicatedly focus on the core business & its expansion.
        </div>
      </div>
      <div className="readymaid_app_card_right">
        <div className="readymaid_app_card_right_card">
          <div className="readymaid_app_card_right_card_heading">
            ReadyMaids Corporate App
          </div>
          <div className="readymaid_app_card_right_card_paragraph">
            Elevate your HR management with our sophisticated app, a
            comprehensive solution designed exclusively for our corporate
            clients. The personalized business hub within the app offers a suite
            of features, including:
          </div>
          <div className="readymaid_app_card_right_card_linea">
            <span>a.</span>Real-time performance monitoring for each employee
          </div>
          <div className="readymaid_app_card_right_card_linea">
            <span>b.</span>Strategic assignment of KPIs & OKRs
          </div>
          <div className="readymaid_app_card_right_card_linea">
            <span>c.</span>Streamlined task assignments on a daily basis
          </div>
          <div className="readymaid_app_card_right_card_linea">
            <span>d.</span>Attendance tracking for efficient workforce
            management
          </div>
          <div className="readymaid_app_card_right_card_linea">
            <span>e.</span>Seamless resource request system
          </div>
          <div className="readymaid_app_card_right_card_linea">
            <span>f.</span>Transparent salary monitoring for employees
          </div>
          <div className="readymaid_app_card_right_card_linea">
            <span>g.</span>Leaves management with easy grant functionality
          </div>
          <div className="readymaid_app_card_right_card_linea">
            <span>h.</span>24/7 dedicated helpline for immediate assistance
          </div>
          <div className="readymaid_app_card_right_card_website">
            ReadyMaids Website
          </div>
          <div className="readymaid_app_card_left_web_para">
            : Experience unparalleled convenience with our website's dedicated
            and personalized business hub, mirroring the comprehensive features
            available on our corporate app. This ensures a seamless and
            professional interface for our valued corporate clients. Our
            objective is to alleviate the complexities of Human Resources
            management through a unique, technologically advanced, and
            convenient approach. This allows you to dedicate your focus to the
            core aspects of your business and its expansion, unburdened by
            HR-related challenges.
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { ourmission } from "../assets";

export function AboutOurMissionSection() {
  return (
    <div
      className="home__payments"
      style={{
        marginTop: "5em",
      }}
    >
      <img src={ourmission} alt="ourmission" className="home__payments__img" />
      <div className="home__payments__overlay">
        <div className="home__payments__overlay__heading">Our Mission</div>
        <div className="home__payments__overlay__info">
          Fostering Growth, Empowering Lives: Our mission is to create
          meaningful job opportunities with security and uphold excellent
          working conditions. We prioritize simplifying processes to give back
          to society and contribute to a more empowered and efficient community.
        </div>
      </div>
    </div>
  );
}

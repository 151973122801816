import React from "react";
import { Zoom } from "react-reveal";
import { joinourteam } from "../assets";

export function HomeJoinTeamSection() {
  return (
    <div className="home__join__team">
      <img
        src={joinourteam}
        alt="joinourteam"
        className="home__join__team__img"
      />
      <Zoom>
        <div className="home__join__team__overlay">
          <div className="home__join__team__overlay__slogan">Join Our Team</div>
          <div className="home__join__team__overlay__sub__heading">
            We Provide
          </div>
          <div className="home__join__team__overlay__heading">
            <span>Best Quality Services</span> for you
          </div>
          <div className="home__join__team__overlay__info">
            At ReadyMaids, we are your unrivaled one-stop destination and
            dynamic marketplace, dedicated to delivering top-tier skilled labor
            services for both individual consumers and corporations. Our
            comprehensive array of offerings includes professionals such as
            Maids, Gardeners, Guards, Chefs, Chauffeurs, Nurses, Nannies,
            Janitors, Plumbers, Electricians, and more, ensuring a sophisticated
            and all-encompassing solution for your workforce requirements.
          </div>
        </div>
      </Zoom>
    </div>
  );
}

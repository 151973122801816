import React, { useEffect, useState } from "react";
import { productsBuisnessFeaturesData } from "../constants/productsBuisnessFeaturesData";
import { ProductsBuisnessPackageEntry } from "../components/ProductsBuisnessPackageEntry";
import { Swiper, SwiperSlide } from "swiper/react";
import { packages } from "../APIs/Repos";
import { ERROR_CODES } from "../Constants";

export function ProductsBuisnessSection() {
  const [noOfSlidesPerView, setNoOfSlidesPerView] = useState(3);
  const [packagesList, setPackagesList] = useState([]);

  useEffect(() => {
    if (window.innerWidth < 1130) setNoOfSlidesPerView(1);
    else setNoOfSlidesPerView(3);

    window.addEventListener("resize", () => {
      if (window.innerWidth < 1130) setNoOfSlidesPerView(1);
      else setNoOfSlidesPerView(3);
    });
    packagesApiCall();
  }, []);

  const packagesApiCall = () => {
    packages()
      .then((res) => {
        if (res.data.error_code == ERROR_CODES.SUCCESS) {
          setPackagesList(res.data.result);
        }
      })
      .catch((error) => {
        console.log("Packages ERROR", error);
      });
  };

  return (
    <>
      <div className="products__buisness__features__heading">
        ReadyMaid for Business{" "}
        <span>Elevating HR Management for Corporations</span>
      </div>
      <div className="products__buisness__features">
        <div className="products__buisness__features__left">
          ReadyMaid for Business stands as the epitome of advanced, intelligent,
          and smart system design, meticulously tailored to meet the specific
          needs of our esteemed corporate clients. As an HR Outsourcing Company,
          we deliver a suite of cutting-edge features that empower our clients
          to manage their workforce in the most structured and efficient manner.
          Our commitment to providing multiple services is executed with a
          unique and professional approach, ensuring a seamless and elevated HR
          outsourcing experience.
        </div>
        <div className="products__buisness__features__right">
          {productsBuisnessFeaturesData.map((feature) => (
            <div
              className="products__buisness__features__right__entry"
              key={JSON.stringify(feature)}
            >
              <div className="products__buisness__features__right__entry__header">
                {feature.icon} {feature.title}
              </div>
              <div className="products__buisness__features__right__entry__info">
                {feature.info}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="products__buisness__packages">
        <Swiper slidesPerView={noOfSlidesPerView} spaceBetween={30}>
          {packagesList != null && packagesList.length > 0
            ? packagesList.map((data, index) => {
                if (index % 2 == 0) {
                  return (
                    <SwiperSlide>
                      <ProductsBuisnessPackageEntry
                        title={data.title}
                        info={data.description}
                        price={data.rate}
                      />
                    </SwiperSlide>
                  );
                } else {
                  return (
                    <SwiperSlide>
                      <ProductsBuisnessPackageEntry
                        title={data.title}
                        info={data.description}
                        price={data.rate}
                        style={{ backgroundColor: "#fad96d", color: "black" }}
                      />
                    </SwiperSlide>
                  );
                }
              })
            : null}
        </Swiper>

        {/* <ProductsBuisnessPackageEntry
          title="Package 1"
          info="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standardLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standardLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard"
          price="$33"
        />
        <ProductsBuisnessPackageEntry
          title="Package 2"
          info="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standardLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standardLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard"
          price="$44"
        />
        <ProductsBuisnessPackageEntry
          title="Package 3"
          info="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standardLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standardLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard"
          price="$55"
        /> */}
      </div>
    </>
  );
}

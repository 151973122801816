import React from "react";
import { Zoom } from "react-reveal";
import ourleaders from "../assets/ourleader.png";

export function AboutOurLeader() {
  return (
    <div className="about__us__our__leader_container">
      <div className="about__us__our__leader_container__overlay">
        <Zoom>
          <img src={ourleaders} alt="" />
        </Zoom>
      </div>
      <div className="about__us__our__leader_container__heading">
        <div className="about__us__our__leader_container__heading__content__heading">
          {/* Our <span>Leader</span> */}
          Leadership at ReadyMaid <span>Pioneering Transformation</span>
        </div>
        <div className="about__us__our__leader__container__heading__content__heading__para">
          At ReadyMaid, our leadership is purpose-driven, motivated to
          revolutionize our country through technology, simplifying processes,
          and enhancing lives. We're constructing an industry where every
          employee receives utmost respect, care, and benefits. Each customer is
          attended to with dedicated precision, fostering a unified corporate
          family that sets a benchmark for others to emulate.
        </div>
      </div>
    </div>
  );
}

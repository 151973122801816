import React from "react";
import foundations from "../assets/foundation.png";
import founder from "../assets/founder.jpg";

export function AboutOurFoundation() {
  return (
    <div className="about__us__our__foundation__all__containers">
      <img src={foundations} alt="" />

      <div className="about__us__our__foundation__all__container">
        <div className="about__us__our__foundation__container">
          <div className="about__us__our__foundation__container__main">
            <div className="about__us__our__foundation__container__content">
              <img
                src={founder}
                alt=""
                style={{ height: "100%", marginTop: -40 }}
              />
            </div>
            <div className="about__us__our__foundation__container__main__two">
              <div className="about__us__our__foundation__container_heading">
                Founder
              </div>
              <div className="about__us__our__foundation__container_heading__two">
                Nasir Abdullah
              </div>
              <div className="about__us__our__foundation__container__para">
                Meet Malik Nasir, the passionate entrepreneur and brain behind
                ReadyMaid. Fueled by a desire to simplify services and boost job
                opportunities, he envisioned a platform that connects providers
                and seekers. Under Malik's guidance, ReadyMaid grew into a
                trusted community, dedicated to fairness and societal impact.
                His vision propels ReadyMaid's journey, making services a means
                of empowerment and progress in communities across Canada.
                {/* Mr. Nasir Abdullah is the founder of ReadyMaid (Private)
                Limited. He has vast exposure of HR and leadership domains, has
                lead multiple teams over the years, and has worked with
                different organizations at different management positions. Nasir
                earned his graduation degree from Punjab University in 2006 and
                MBA from Bahria University in 2008 with specialization in Human
                Resource Management. */}
              </div>
              {/* <div className="about__us__our__foundation__container__paras">
                Prior to founding Readymaid Private Limited, Nasir worked for
                PTCL Group, a major telecom company of Pakistan at different
                management positions including HR Operations, HR Services,
                Organization Development, Learning & Development and
                Recruitment. He has a keen interest in Leadership and Learning &
                Development domains. He has delivered many lectures on HR and
                leadership at different platforms including British Council,
                PTCL, Bahria University. He has also appeared on different TV
                talk shows.
              </div>
              <div className="about__us__our__foundation__container__paras">
                Mr. Nasir believes that entrepreneurship is the way forward for
                our economy to sustain, progress, flourish and boom. With
                thousands of students graduating each year and inflation
                skyrocketing, there is a need of more job creators than seekers
                in the market. He also believes in Inclusive Economy that
                benefits each segment of the society and vows to lay foundation
                stone towards it by empowering the neglected strata of the
                society through his startup. He is a firm believer of Can Do
                approach and thinks the only failure is not trying and going
                after your passion.
              </div> */}
              {/* <div className="about__us__our__foundation__container__paras">
                Mr. Nasir loves cycling and have participated in many cycling
                competitions both nationally & internationally. He represented
                Pakistan in mHealth Grand Tour, a cycling expedition from Paris
                to Geneva where people from over 20 countries participated. He
                also represented Pakistan in cycling competition in Dubai. Mr.
                Nasir loves traveling and have been to the south & north of
                Pakistan as well as Europe, America, Canada & UAE. His favorite
                travel destination though still remains Hunza-the north of
                Pakistan. He loves nature and have organized many tree
                plantation campaigns in the past. Running, working out, hiking
                and cycling remains his favorite pass time hobbies.
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

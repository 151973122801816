import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function NavLink({ to, children, onClick, style, className }) {
  const navigate = useNavigate();
  localStorage.setItem("path", to);
  return (
    <div className={"header__content__nav__link"} style={style}>
      <input
        type="radio"
        className="header__content__nav__link__input"
        name="header__content__nav__link"
        defaultChecked={
          window.location.pathname === localStorage.getItem("path")
            ? true
            : false
        }
        id={children}
        onChange={() => navigate(to)}
        onClick={onClick}
      />
      <div className={"header__content__nav__link__content"}>{children}</div>
    </div>
  );
}

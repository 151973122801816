import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="privacy__policy">
      <div className="privacy__policy__header">Privacy Policy</div>
      <div className="privacy__policy__content">
        <div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "8.0pt",
              marginLeft: "0in",
              lineHeight: "107%",
              fontSize: "15px",
            }}
          >
            <span>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: "0in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd, or its affiliates (collectively “Readymaid”,
              “we” or “us”) collects information about you when you use our
              mobile applications, websites, call-Centre and other products and
              services (collectively, the “Services”) and through other
              interactions and communications you have with us. This privacy
              policy sets out the basis on which any information, including any
              personal data, we collect from you, or you provide to us, will be
              processed by Readymaid. Each time you access or use the Services
              or provide us with information, by doing so you are accepting and
              consenting to the practices described in this privacy policy.
            </span>
          </p>
          <div
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "8.0pt",
              marginLeft: "0in",
              lineHeight: "107%",
              fontSize: "15px",
            }}
          >
            <ul
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "0in",
              }}
            >
              <li
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: "15px",
                }}
              >
                <strong>
                  <span>Scope and Application</span>
                </strong>
              </li>
            </ul>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",
              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              This privacy policy (this “privacy policy") applies to persons
              anywhere in the world who access or use our Services (“Users”) as
              well as to drivers, partner transportation companies, or any other
              persons who use the Readymaid (Pvt) Ltd. platform under license
              (collectively “Champions”).
            </span>
          </p>
          <div
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "8.0pt",
              marginLeft: "0in",
              lineHeight: "107%",
              fontSize: "15px",
            }}
          >
            <ul
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "0in",
              }}
            >
              <li
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: "15px",
                }}
              >
                <strong>
                  <span>Collection of Information</span>
                </strong>
              </li>
            </ul>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <em>
              <span
                style={{
                  color: "black",
                  background: "white",
                }}
              >
                Information You Provide to Us
              </span>
            </em>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We collect information you provide directly to us through your
              access or use of the Services, such as when you create or modify
              your account, request on-demand services, contact customer
              support, or otherwise communicate with us. This information may
              include: your name, email address, phone number, postal address,
              profile picture, payment method, financial and credit card
              information, and other information you choose to provide.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Please note that we do not knowingly collect, keep or maintain
              personal information from children under the age of 18 through the
              Services, as we require that all users represent to us that they
              are at least 18 years old.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <em>
              <span
                style={{
                  color: "black",
                  background: "white",
                }}
              >
                Information We Collect Through Your Use of Our Services
              </span>
            </em>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              When you use our Services, we collect personal information (i.e.
              information that could be used to contact you directly such as
              full name, postal address, phone number, credit/debit card
              information, or email address) (“Personal Information”) and
              demographic information (i.e. information that you submit, or that
              we collect, that is not personal information; this may include,
              but is not limited to, post code, hometown, gender, username,
              device information, including the type of mobile device you use, a
              unique device identifier (for example, your device's IMEI number,
              the MAC address of the device's wireless network interface, or the
              mobile phone number used by the device), mobile network
              information, your mobile operating system, the type of mobile
              browser you use, time zone setting, device location, IP address,
              SMS data, transaction information, age/birth date, browsing
              history information, searching history information, and
              registration history information) (“Demographic Information” and,
              together with Personal Information, “Personal Data”).
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We also use GPS technology to determine your current location.
              Some of our location-enabled Services require your personal data
              for the feature to work. If you wish to use the particular
              feature, you will be asked to consent to your data being used for
              this purpose.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <em>
              <span
                style={{
                  color: "black",
                  background: "white",
                }}
              >
                Information We Collect From Other Sources
              </span>
            </em>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We may also receive information from other sources and combine
              that with Personal Data we receive from you or collect through our
              Services. For example:
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0.5in" }}>
            <li>
              <span style={{ background: "white" }}>
                If you choose to link, create, or log in to your Readymaid (Pvt)
                Ltd. account with a payment provider or social media service
                (e.g., Facebook), or if you engage with a separate app or
                website that uses our API (or whose API we use), we may receive
                information about you or your connections from that site or app.
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                If your employer uses one of our enterprise solutions, we may
                receive information about you from your employer.
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                If your employer uses one of our enterprise solutions, we may
                receive information about you from your employer.
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                If you also interact with our Services in another capacity, for
                instance as a Captain, we may combine or associate that
                information with Personal Data we have collected from you in
                your capacity as a User or rider.
              </span>
            </li>
          </ul>
          <p
            style={{
              marginTop: "5.0pt",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: "0in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                background: "white",
              }}
            >
              &nbsp;
            </span>
          </p>
          <div
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "8.0pt",
              marginLeft: "0in",
              lineHeight: "107%",
              fontSize: "15px",
            }}
          >
            <ul
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "0in",
              }}
            >
              <li
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: "15px",
                }}
              >
                <strong>
                  <span>Use of Personal Data</span>
                </strong>
              </li>
            </ul>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We may use Personal Data which you provide to us or we collect
              from you to:
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0.5in" }}>
            <li>
              <span style={{ background: "white" }}>
                Provide, maintain, and improve our Services, including, for
                example, to facilitate payments, send receipts, provide products
                and services you request (and send related information), develop
                new features, provide customer support to Users and Champions,
                develop safety features, authenticate users, and send product
                updates and administrative messages;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Perform internal administration and operations, including, for
                example, to prevent fraud and abuse of our Services; to
                troubleshoot software bugs and operational problems; to conduct
                data analysis, testing, and research; and to monitor and analyze
                usage and activity trends;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Send or facilitate communications (i) between you and a Captain,
                such as estimated times of arrival (ETAs), or (ii) between you
                and a contact of yours at your direction in connection with your
                use of certain features, such as referrals or invites;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Send you communications we think will be of interest to you,
                including information about products, services, promotions,
                news, and events of Readymaid (Pvt) Ltd. and other companies,
                where permissible and according to local applicable laws; and to
                process contest, sweepstake, or other promotion entries and
                fulfill any related awards;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Notify you about changes to our Services;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Allow you to participate in interactive features of our
                Services;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Keep our Services safe and secure; and
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Personalize and improve the Services, including to provide or
                recommend features, content, social connections, referrals, and
                advertisements.
              </span>
            </li>
          </ul>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li>
              <strong>
                <span>Storage and Transfer of Personal Data</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You agree that we have the right to transfer the Personal Data
              described in this privacy policy to and from, and process and
              store it in, the Islamic Republic of Pakistan, and other
              countries, some of which may have less protective data protection
              laws than the region in which you reside. Where this is the case,
              we will take appropriate measures to protect your Personal Data in
              accordance with this privacy policy. By submitting your Personal
              Data, you agree to this transfer, storing or processing. We will
              take all steps reasonably necessary to ensure your data is treated
              securely and in accordance with this privacy policy.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              To preserve the integrity of our databases, to carry out on-going
              Services on your behalf, for research, analytics and statistics
              purposes and to ensure compliance with applicable laws and
              regulations, we retain Personal Information and Demographic
              Information submitted by Users and Champions for a reasonable
              length of time. Readymaid (Pvt) Ltd. is not responsible for the
              accuracy of the information you provide, and will modify or update
              your Personal Information in our databases upon your request. If
              required by law or at your request, we will erase or archive from
              active use your Personal Information. By accessing or using the
              Services, you do hereby represent and warrant that you understand
              that all information submitted by you through the Services or
              otherwise to Readymaid (Pvt) Ltd. may be used by Readymaid (Pvt)
              Ltd. in accordance with applicable laws, this privacy policy and
              our customer terms of use.
            </span>
          </p>
          {/* <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Our nominated representative for the purpose of the Acts is Zen
              Alkourdi.
            </span>
          </p> */}
          {/* <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              To preserve the integrity of our databases, to carry out on-going
              Services on your behalf, for research, analytics and statistics
              purposes and to ensure compliance with applicable laws and
              regulations, we retain Personal Information and Demographic
              Information submitted by Users and Resources for a reasonable
              length of time. ReadyMaid is not responsible for the accuracy of
              the information you provide, and will modify or update your
              Personal Information in our databases upon your request. If
              required by law or at your request, we will erase or archive from
              active use your Personal Information. By accessing or using the
              Services, you do hereby represent and warrant that you understand
              that all information submitted by you through the Services or
              otherwise to ReadyMaid may be used by ReadyMaid in accordance with
              applicable laws, this privacy policy and our customer terms of
              use.
            </span>
          </p> */}
          <div
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "8.0pt",
              marginLeft: "0in",
              lineHeight: "107%",
              fontSize: "15px",
            }}
          >
            <ul
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "0in",
              }}
            >
              <li
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: "15px",
                }}
              >
                <strong>
                  <span>Sharing of Personal Data</span>
                </strong>
              </li>
            </ul>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We may share Personal Data which we collect about you as described
              in this privacy policy or as described at the time of collection
              or sharing, including as follows:
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <em>
              <span
                style={{
                  color: "black",
                  background: "white",
                }}
              >
                Through Our Services
              </span>
            </em>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We may share your Personal Data:
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0.5in" }}>
            <li>
              <span style={{ background: "white" }}>
                With Champions to enable them to provide the Services you
                request. For example, we share your name and pickup and/or
                drop-off locations with Champions;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                With third parties to provide you a service you requested
                through a partnership or promotional offering made by a third
                party or us;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                With third parties with whom you choose to let us share your
                Personal Data, for example other apps or websites that integrate
                with our API or Services, or those with an API or Service with
                which we integrate; and
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                With your employer (or similar entity) and any necessary third
                parties engaged by us or your employer, if you participate in
                any of our enterprise solutions.
              </span>
            </li>
          </ul>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li>
              <strong>
                <span>Other Important Sharing</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We may share your Personal Data;
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0.5in" }}>
            <li>
              <span style={{ background: "white" }}>
                With Readymaid (Pvt) Ltd. subsidiaries and affiliated entities;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                With vendors, consultants, marketing and advertising partners,
                and other service providers who need access to such Personal
                Data to carry out work on our behalf or to perform any contract
                we enter into with them;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                In response to a request for information by a competent
                authority if we believe disclosure is in accordance with, or is
                otherwise required by, any applicable law, regulation, or legal
                process;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                With law enforcement officials, government authorities, or other
                third parties to enforce or apply our Terms of Service, to
                investigate potential breaches or if we believe your actions are
                inconsistent with our Terms of Service, or to protect the
                rights, property, or safety of Readymaid (Pvt) Ltd. or others;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                With third parties in connection with, or during negotiations
                of, any merger, sale of company assets, consolidation or
                restructuring, financing, or acquisition of all or a portion of
                our business by or into another company;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                If we otherwise notify you and you consent to the sharing; With
                third parties in an aggregated and/or anonymized form which
                cannot reasonably be used to identify you
              </span>
            </li>
          </ul>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li>
              <strong>
                <span>Your Choices</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <em>
              <span
                style={{
                  color: "black",
                  background: "white",
                }}
              >
                Marketing and Opting Out
              </span>
            </em>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd. provides Users and Champions the opportunity
              to opt-out of receiving communications from us and our partners at
              the point where we request information about the visitor. You have
              the option to ask us not to process your Personal Data for
              marketing purposes and to remove it from our database, to not
              receive future communications or to no longer receive our
              Services.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <em>
              <span
                style={{
                  color: "black",
                  background: "white",
                }}
              >
                Access to Information
              </span>
            </em>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              The Acts give you the right to access information held about you.
              Your right of access can be exercised in accordance with the Acts.
              Any access request will be subject to a fee of Euros 6.35 (or
              equivalent in local currency at prevailing exchange rates) to meet
              our costs in providing you with details of the information we hold
              about you.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <em>
              <span
                style={{
                  color: "black",
                  background: "white",
                }}
              >
                Changes to this Privacy Policy
              </span>
            </em>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We may change this privacy policy from time to time. If we make
              significant changes in the way we treat your Personal Data, or to
              the privacy policy, we will provide you notice through the
              Services or by some other means, such as email. Your continued use
              of the Services after such notice constitutes your consent to the
              changes. We encourage you to periodically review this privacy
              policy for the latest information on our privacy practice.
            </span>
          </p>
          <div
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "8.0pt",
              marginLeft: "0in",
              lineHeight: "107%",
              fontSize: "15px",
            }}
          >
            <ul
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "0in",
              }}
            >
              <li
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: "15px",
                }}
              >
                <strong>
                  <span>Security Precautions</span>
                </strong>
              </li>
            </ul>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Your Readymaid (Pvt) Ltd. profile is password-protected so that
              only you and authorized Readymaid (Pvt) Ltd. employees have access
              to your account information. Readymaid (Pvt) Ltd. staff will never
              proactively reach out to you and ask for any personal account
              information, including your password.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd. makes every effort to ensure that your
              Personal Data is secure on its system. Readymaid (Pvt) Ltd. has
              staff dedicated to maintaining our privacy policy as set forth
              herein and other privacy initiatives, periodically reviewing Web
              security and making sure that every Readymaid (Pvt) Ltd. employee
              is aware of our security practices. Unfortunately, no data
              transmission over the Internet can be guaranteed to be 100%
              secure. As a result, Readymaid (Pvt) Ltd. cannot guarantee the
              security of any Personal Data you transmit to us, and you do so at
              your own risk. If You have any further questions on this issue,
              refer to our customer terms of use. To the extent permitted by
              applicable law, Readymaid (Pvt) Ltd. expressly disclaims any
              liability that may arise should any other individuals obtain the
              Personal Data you submit to Readymaid (Pvt) Ltd..
            </span>
          </p>
          <div
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "8.0pt",
              marginLeft: "0in",
              lineHeight: "107%",
              fontSize: "15px",
            }}
          >
            <ul
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "0in",
              }}
            >
              <li
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: "15px",
                }}
              >
                <strong>
                  <span>Cookies</span>
                </strong>
              </li>
            </ul>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Our website uses cookies to distinguish you from other users of
              our website. This helps us to provide you with a good experience
              when you browse our website and also allows us to improve our
              website and the Services we provide. By accessing and browsing our
              website, you are agreeing to our use of cookies.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Notice to RTA Readymaid (Pvt) Ltd. LLC customers
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              If you are accessing or using products or services provided by RTA
              Readymaid (Pvt) Ltd. LLC, this section applies to you.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd. has been engaged by RTA Readymaid (Pvt) Ltd.
              LLC to provide certain services in support of its business. As a
              result, Readymaid (Pvt) Ltd. may be required to receive, store,
              generate or otherwise process your personal data on behalf of RTA
              Readymaid (Pvt) Ltd. LLC and to enable you to access or use the
              products or services provided by RTA Readymaid (Pvt) Ltd. LLC.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              To the extent that Readymaid (Pvt) Ltd. does receive, store or
              otherwise process your personal data in connection with its
              provision of services to RTA Readymaid (Pvt) Ltd. LLC, it does so
              solely on behalf of RTA Readymaid (Pvt) Ltd. LLC which is
              responsible for determining the purpose and means of that
              processing and not for any other purpose. To the fullest extent
              permitted under applicable laws, including the applicable data
              protection laws, Readymaid (Pvt) Ltd. shall have no liability
              under or in connection with the receipt, storage or otherwise
              processing of your personal data in connection with Readymaid
              (Pvt) Ltd.’s provision of services to RTA Readymaid (Pvt) Ltd.
              LLC.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Each time you access or use the products or services provided by
              RTA Readymaid (Pvt) Ltd. LLC or provide us with information, by
              doing so you accept and consent to the practices described in this
              notice.
            </span>
          </p>
          <div
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "8.0pt",
              marginLeft: "0in",
              lineHeight: "107%",
              fontSize: "15px",
            }}
          >
            <ul
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "0in",
              }}
            >
              <li
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: "15px",
                }}
              >
                <strong>
                  <span>Contact Us</span>
                </strong>
              </li>
            </ul>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              If you have any questions, comments and requests related to this
              Privacy Statement, or if you have any complaints related to how
              Readymaid (Pvt) Ltd. processes your personal data, please contact
              us at info@Readymaid.com.pk.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

// export const readymaidConceptsData = [
//   "It is not about creating a marketplace or service providing platform but   creating an industry that doesn’t exist at all.",
//   "It is about empowering the most neglected segment of the society.",
//   "It is about providing a corporate exposure and working environment to the ones who can never think about it.",
//   "It is about providing job security to the ones who find it difficult even to get (job) one. ",
//   "It is not about gaining from the society but giving back to it.",
//   "It is not about calling people for the job rather reaching out to them for it.",
//   "It is about creating a platform where anyone can come to find a job.",
//   "It is about creating an environment where people are not judged by their qualification and skill sets but their identity i.e. their existence.",
//   "It is not about finding but making the right resource.",
// ];

export const readymaidConceptsData = [
  `ReadyMaid is a groundbreaking concept that transcends the traditional marketplace model. It's not just about offering services; it's about creating an entirely new paradigm in the job industry.
Our platform goes beyond conventional job offerings by providing an innovative approach to job security. We aim to reshape the employment landscape, making opportunities accessible to a wider audience.
ReadyMaid is more than just a platform; it's a dynamic space where anyone can find job opportunities. We focus on creating an environment where individuals are valued for who they are, independent of their qualifications.
Our mission is to go beyond simply finding the right resource; we actively participate in shaping and cultivating talent. At ReadyMaid, we're committed to revolutionizing employment dynamics, emphasizing inclusivity, and recognizing the inherent potential of every individual.
`,
];

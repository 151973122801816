export const productsBuisnessFeaturesData = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41.281"
        height="40"
        viewBox="0 0 41.281 40"
      >
        <g
          id="Group_4258"
          data-name="Group 4258"
          transform="translate(-905.119 -170.862)"
        >
          <path
            id="Path_9892"
            data-name="Path 9892"
            d="M919.423,226.7c-.192,2.116-.359,4.167-.569,6.214-.552,5.369-1.169,10.731-1.57,16.116a3.2,3.2,0,0,1-3.231,3.013c-.73.027-1.463.029-2.192-.013a3.022,3.022,0,0,1-2.978-2.591,50.374,50.374,0,0,1-.526-5.4,17.852,17.852,0,0,0-2.126-8.583,9.259,9.259,0,0,1-1.109-4.961c.029-1.461-.015-2.925.033-4.385a4.357,4.357,0,0,1,3.614-4.283c.676-.1,1.048.052,1.248.757.431,1.521.939,3.021,1.5,4.528.146-1.172,1.006-2.263.188-3.532-.363-.563.237-1.413.933-1.433.734-.022,1.588-.231,1.745.892.021.153.093.359.023.456-.893,1.247.047,2.436.2,3.684.478-1.5.993-2.991,1.419-4.506a1.027,1.027,0,0,1,1.28-.876,10.122,10.122,0,0,1,4.645,1.17,6.136,6.136,0,0,0,5.31.546c1.14-.383,2.311-.68,3.478-.976a2.107,2.107,0,0,1,2.663,1.374,2.08,2.08,0,0,1-1.381,2.723,28.445,28.445,0,0,1-6.519,1.681,5.983,5.983,0,0,1-2.79-.449C921.632,227.5,920.562,227.108,919.423,226.7Z"
            transform="translate(0 -41.204)"
            fill="currentColor"
          />
          <path
            id="Path_9893"
            data-name="Path 9893"
            d="M973.46,179.308c-.348-.016-.7-.011-1.043-.051-.981-.113-1.17-.4-.709-1.256a6.278,6.278,0,0,0,.635-4.23c.11-.605.585-.463.975-.464,3.178,0,6.356,0,9.535-.01.3,0,.635.14.9-.139.009-.381-.008-.764.032-1.141.076-.721.418-1.189,1.222-1.153.759.034,1.048.5,1.073,1.188.013.348-.011.7-.019,1.044.063.266.284.205.458.205,4.14,0,8.28.009,12.42,0a.825.825,0,0,0,.272-.072.922.922,0,0,1,.868,1.125.935.935,0,0,1-1.009.939c-.816-.063-.741.407-.739.935q.03,8.709.044,17.419c.006,2.287-.93,3.587-2.987,4.132a59.961,59.961,0,0,0-6.855.045c-.15.778-.29,1.478.528,2.1a.961.961,0,0,1-.561,1.653,10.138,10.138,0,0,1-1.116.082c1.359,1.351,2.613,2.6,3.863,3.843.269.269.544.536.785.829a1.063,1.063,0,1,1-1.486,1.518,6.13,6.13,0,0,1-.49-.451c-1.724-1.68-3.448-3.361-5.155-5.026-1.639,1.62-3.35,3.316-5.068,5a3.879,3.879,0,0,1-.8.66.973.973,0,0,1-1.29-.214.927.927,0,0,1-.082-1.225,4.786,4.786,0,0,1,.61-.724c1.373-1.358,2.753-2.707,4.112-4.04-.159-.29-.333-.221-.469-.22-.565.005-1.119-.037-1.322-.679-.19-.6.108-.984.595-1.357.558-.427.13-1.124.277-1.681-.052-.072-.073-.167-.2-.166-2.216.023-4.434-.088-6.649.062-.938-.016-1.365-.411-1.11-1.4a13.034,13.034,0,0,0,.2-1.536,2.154,2.154,0,0,0,.906.846c.186.177.419.115.634.116q9.661,0,19.323,0c.217,0,.448.053.641-.1a1.516,1.516,0,0,0,.963-1.647c-.023-6.241-.014-12.482-.015-18.724-.2-.229-.475-.131-.715-.131q-10.545-.008-21.089,0c-.231,0-.514-.133-.692.151v-.054l.054,0c-.343.65-.085,1.347-.155,2.019A10.117,10.117,0,0,1,973.46,179.308Z"
            transform="translate(-53.681 0)"
            fill="currentColor"
          />
          <path
            id="Path_9894"
            data-name="Path 9894"
            d="M928.451,170.874a4.262,4.262,0,0,1-.041,8.524,4.262,4.262,0,1,1,.041-8.524Z"
            transform="translate(-15.364 -0.01)"
            fill="currentColor"
          />
          <path
            id="Path_9895"
            data-name="Path 9895"
            d="M982.929,193.286c.235-.393.624-.243.95-.243q10.292-.013,20.584,0c.327,0,.711-.15.962.221Z"
            transform="translate(-62.953 -17.921)"
            fill="currentColor"
          />
          <path
            id="Path_9896"
            data-name="Path 9896"
            d="M1008.5,300.967c-.274.326-.65.193-.981.193q-9.321.012-18.643,0c-.33,0-.708.135-.974-.2Z"
            transform="translate(-66.979 -105.253)"
            fill="currentColor"
          />
          <path
            id="Path_9897"
            data-name="Path 9897"
            d="M1061.022,182.7c-.23.247-.532.165-.808.165q-5.713.007-11.427,0c-.323,0-.721.146-.915-.295a17.906,17.906,0,0,0,3.02.076C1054.268,182.673,1057.645,182.681,1061.022,182.7Z"
            transform="translate(-115.495 -9.468)"
            fill="currentColor"
          />
          <path
            id="Path_9898"
            data-name="Path 9898"
            d="M986.9,182.891c-.239.384-.625.231-.947.232-3.123.011-6.246.008-9.37.006-.408,0-.833-.06-1.089.374-.379-.721.161-.592.551-.592l9.809,0C986.206,182.909,986.555,182.9,986.9,182.891Z"
            transform="translate(-56.836 -9.732)"
            fill="currentColor"
          />
          <path
            id="Path_9899"
            data-name="Path 9899"
            d="M987.922,311.064c.525-.334,1.106-.14,1.658-.152,1.419-.031,2.839-.019,4.258,0,.322,0,.713-.152.931.259q-2.9-.024-5.8-.049C988.617,311.115,988.27,311.083,987.922,311.064Z"
            transform="translate(-66.992 -113.272)"
            fill="currentColor"
          />
          <path
            id="Path_9900"
            data-name="Path 9900"
            d="M1060.825,311.246a.561.561,0,0,1,.51-.193c1.969,0,3.939,0,5.908.006a1.519,1.519,0,0,1,.437.142C1065.4,311.352,1063.11,311.239,1060.825,311.246Z"
            transform="translate(-125.975 -113.423)"
            fill="currentColor"
          />
          <path
            id="Path_9901"
            data-name="Path 9901"
            d="M981.841,198.17c0-1.048-.008-2.1,0-3.143,0-.3-.122-.652.247-.852-.011,1.129-.011,2.258-.044,3.387C982.043,197.764,982.244,198.085,981.841,198.17Z"
            transform="translate(-62.062 -18.862)"
            fill="currentColor"
          />
        </g>
      </svg>
    ),
    title: "Training & Development Excellence",
    info: "At our organization, we prioritize the continuous growth and enhancement of our workforce through comprehensive training, education, and development programs. By investing in the skills and knowledge of our people, we ensure that our clients receive maximum benefit from a highly skilled and proficient team.",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.288"
        height="42.731"
        viewBox="0 0 34.288 42.731"
      >
        <g
          id="Group_4259"
          data-name="Group 4259"
          transform="translate(-18093.333 -5847)"
        >
          <path
            id="Path_10070"
            data-name="Path 10070"
            d="M-199.729,14.074c1.048.162,1.045,1.048.883,1.867-.216,1.09-.276,2.5-.978,3.143-1.2,1.108-1.325,2.609-2.088,3.839a6.229,6.229,0,0,1-6,3.218,6.475,6.475,0,0,1-5.922-4.594,16,16,0,0,0-1.147-2.125,17.534,17.534,0,0,1-1.2-4.445c-.029-.125.039-.375.125-.41,1.291-.525.637-1.539.591-2.372-.147-2.682.423-5.058,2.745-6.684l-.662-1.539c.6.031,1.028.073,1.451.069,1.639-.014,3.285.039,4.916-.089A4.556,4.556,0,0,1-202.64,6.1a1.37,1.37,0,0,0,.675.632c1.773.427,2.385,1.735,2.45,3.33C-199.463,11.358-199.645,12.662-199.729,14.074Z"
            transform="translate(18316.467 5843.065)"
            fill="currentColor"
          />
          <path
            id="Path_10071"
            data-name="Path 10071"
            d="M-233.713,114.575c.474-1.313.866-2.319,1.185-3.347a1.352,1.352,0,0,0-.182-.821c-.183-.408-.459-.774-.637-1.183-.74-1.7-.1-2.721,1.727-2.783,1.991-.067,2.963,1.188,1.873,2.833-1.28,1.932-.149,3.339.564,4.975l3.157-9.915c2.586,1.031,5.109,2.033,7.63,3.043a3.946,3.946,0,0,1,2.442,3.489c-3-.979-5.731-.735-8.131,1.219a8.06,8.06,0,0,0-2.866,7.634c-.4.065-.828.165-1.258.2a34.4,34.4,0,0,1-17.3-2.827,2.327,2.327,0,0,1-1.605-2.479c.087-1.56.039-3.139.281-4.674a3.372,3.372,0,0,1,2.369-2.613c2.468-.939,4.905-1.959,7.474-2.992Z"
            transform="translate(18340.449 5765.237)"
            fill="currentColor"
          />
          <path
            id="Path_10072"
            data-name="Path 10072"
            d="M-143.964,138.149a6.28,6.28,0,0,0-6.305,6.356,6.211,6.211,0,0,0,6.194,6.205,6.226,6.226,0,0,0,6.321-6.319A6.324,6.324,0,0,0-143.964,138.149Zm3.763,7.19h-2.888v2.879h-1.846V145.33h-2.927v-1.764h2.914V140.64h1.794v2.881h2.953Z"
            transform="translate(18265.375 5739.021)"
            fill="currentColor"
          />
        </g>
      </svg>
    ),
    title: "Strategic Recruitment Solutions",
    info: "Our dedicated recruitment services encompass the meticulous management of attracting, screening, and selecting the right human resources for our esteemed corporate clients. We employ a strategic approach to ensure a tailored fit between candidates and client requirements, facilitating the acquisition of top-tier talent for your organization.",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33.967"
        height="34.517"
        viewBox="0 0 33.967 34.517"
      >
        <g
          id="Group_4260"
          data-name="Group 4260"
          transform="translate(-18077 -5918.059)"
        >
          <path
            id="Path_10076"
            data-name="Path 10076"
            d="M-286.266,285.143a12.97,12.97,0,0,1-17.875-3.6,12.932,12.932,0,0,1,2.128-16.807,13.175,13.175,0,0,1,18.722,1.422c1.241-1.132,2.513-2.235,3.713-3.411.63-.617,1.078-.642,1.6.078.328.449.737.839,1.169,1.321-.237.268-.421.515-.643.72-5.054,4.659-10.123,9.3-15.158,13.981-.613.569-.963.553-1.534-.039-1.786-1.852-3.615-3.664-5.462-5.455-.572-.555-.68-.936-.032-1.532,1.57-1.445,1.093-1.572,2.7.014,1.177,1.16,2.344,2.329,3.638,3.616l7.787-7.125c-2.676-4.039-8.83-5.178-13.185-2.5a10.135,10.135,0,0,0-4.33,12.043,10.24,10.24,0,0,0,10.737,6.673,10.271,10.271,0,0,0,8.969-9.259,1.8,1.8,0,0,0,.025-.631c-.581-1.963.683-2.946,2.034-3.889,1.074,1.82,1.123,7.188-1.732,11.181q2.6,2.182,5.218,4.38c1.324,1.109,2.658,2.205,3.975,3.322a3.5,3.5,0,0,1,.37,5.359,3.471,3.471,0,0,1-5.279-.5c-2.368-2.889-4.685-5.82-7.025-8.732C-285.9,285.556-286.09,285.35-286.266,285.143Z"
            transform="translate(18383.313 5656.576)"
            fill="currentColor"
          />
        </g>
      </svg>
    ),
    title: "Antecedent Verification Assurance",
    info: "Eliminating the hassle for our clients, we prioritize the thorough verification of all our personnel before placement with any client. This commitment ensures that our clients receive trustworthy and reliable professionals, contributing to a secure and efficient working environment.",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33.638"
        height="34.312"
        viewBox="0 0 33.638 34.312"
      >
        <g
          id="Group_4261"
          data-name="Group 4261"
          transform="translate(-18089.182 -5989.724)"
        >
          <path
            id="Path_10073"
            data-name="Path 10073"
            d="M-157.575,524.632l-1.067-.989c1.857-2.673,6.3-3.215,9.3-1.176a7.249,7.249,0,0,1,1.92,10.031,7.086,7.086,0,0,1-9.605,2.123l.72-1.264c3.627,1.228,6.309.455,7.78-2.2a5.6,5.6,0,0,0-1.266-6.962C-152.034,522.285-154.842,522.418-157.575,524.632Z"
            transform="translate(18269.002 5468.495)"
            fill="currentColor"
          />
          <path
            id="Path_10074"
            data-name="Path 10074"
            d="M-140.424,536l.424.97a1.191,1.191,0,0,1,.44.08,5.525,5.525,0,0,1,.636.5,4.137,4.137,0,0,1-.736.2c-.3.028-.646-.115-.9-.007-.307.133-.7.418-.75.693a1.154,1.154,0,0,0,.468.893,12.231,12.231,0,0,0,1.378.647,1.726,1.726,0,0,1,.107,3.123,2.718,2.718,0,0,0-.577.795c-.115.171-.214.352-.462.765l-.249-1.182c-1.419-.381-1.419-.381-1.287-1.024a7.383,7.383,0,0,0,1.692.112,1.175,1.175,0,0,0,.84-.707,1.446,1.446,0,0,0-.5-1.088,8,8,0,0,0-1.372-.654,1.567,1.567,0,0,1-.126-2.859A6.016,6.016,0,0,0-140.424,536Z"
            transform="translate(18255.967 5456.743)"
            fill="currentColor"
          />
          <path
            id="Path_10075"
            data-name="Path 10075"
            d="M-233.1,559.982c-.827-3.861-1.636-7.727-2.531-11.572A2.8,2.8,0,0,0-236.692,547a8.056,8.056,0,0,0-5.219-1.427c-.5.012-1.155.36-1.489-.456a1.586,1.586,0,0,1,.367-1.978,10.6,10.6,0,0,0,3.4-8.637,6.3,6.3,0,0,0-3.377-5.472,9.924,9.924,0,0,0-7.445-.79c-3.887,1.077-5.707,4.363-5.057,8.815a11.368,11.368,0,0,0,3.367,6.174,1.386,1.386,0,0,1,.177,1.9c-.2.3-.823.362-1.272.455a8.913,8.913,0,0,1-1.019.013c-2.657.226-5.454,1.109-6.153,4.752-.6,3.142-1.139,6.3-1.713,9.445-.139.759.132,1.023.918,1.019,4.534-.029,9.068-.014,13.6-.014h13.5C-233.544,560.795-232.909,560.872-233.1,559.982Zm-16.336-7.1a4.05,4.05,0,0,1-1.082-2.8c.12-1.624.029-3.264.029-5.031l1.527.43c-.221.7-.451,1.372-.618,2.063a3.309,3.309,0,0,0,.648.293Zm-.7-9.081c-1.247-1.334-2.34-2.816-3.462-4.263a4.227,4.227,0,0,1-.553-1.2c-.8-2.276-.246-3.263,2.024-3.948a4.224,4.224,0,0,1,4.522.916c1.675,1.582,3.679,1.015,5.62.654.329-.061.646-.185,1.051-.3a4.384,4.384,0,0,1-.654,3.792,45.95,45.95,0,0,1-3.531,4.337C-246.146,544.877-249.121,544.889-250.14,543.8Zm4.441,8.815-.567-4.767c.976-.165.977-.85-.209-2.3l1.691-.5c0,1.728-.066,3.4.024,5.07A3.429,3.429,0,0,1-245.7,552.614Z"
            transform="translate(18351.34 5463.228)"
            fill="currentColor"
          />
        </g>
      </svg>
    ),
    title: "Comprehensive Payroll Management",
    info: "With meticulous attention to detail, we handle the payroll management for all our personnel. Going beyond basic salary processing, we extend various perks including superannuation, medical benefits, old age benefits, insurance, and more. Our commitment is to ensure that our people receive a comprehensive and competitive compensation package, fostering their well-being and job satisfaction.",
  },
];

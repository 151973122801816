import axios from "axios";

//#region Sign Up API

export const businessSignUp = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/business-user/signup",
    data
  );
};

export const publicSignUp = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/public-user/signup",
    data
  );
};

export const resourceSignUp = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/service-provider/signup",
    data
  );
};

//#endregion

//#region service API

export const services = () => {
  return axios.get(
    process.env.REACT_APP_API_URL + "api/service/get-active-service-list"
  );
};

//#endregion

//#region Contact Us API

export const contactUs = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "api/contact-us/add", data);
};

//#endregion

//#region Package API

export const packages = () => {
  return axios.get(
    process.env.REACT_APP_API_URL +
      "api/package/get-active-package-list-without-pagination"
  );
};

//#endregion

//#region services API

export const getServices = (type) => {
  return axios.get(
    process.env.REACT_APP_API_URL +
      "/api/service/get-by-service-type?service_type=" +
      type
  );
};

//#endregion

import React, { useEffect, useState } from "react";
import { BenifitsAppCard } from "../components/BenifitsAppCard";
import { BenifitsFeature } from "../components/BenifitsFeature";
import { BenifitsJumbotron } from "../components/BenifitsJumbotron";
import { BenifitsSafty } from "../components/BenifitsSafty";
import { BenifitsSmartSolution } from "../components/BenifitsSmartSolution";
import "swiper/scss";
import { BenifitstoResources } from "./BenifitstoResources";
import { Zoom } from "react-reveal";

export default function Benifits() {
  const [slidesPerPage, setSlidesPerPage] = useState(4);
  window.scrollTo(0, 0);
  useEffect(() => {
    if (window.innerWidth <= 550) {
      setSlidesPerPage(1);
    } else if (window.innerWidth <= 800) {
      setSlidesPerPage(2);
    } else if (window.innerWidth <= 980) {
      setSlidesPerPage(3);
    } else if (window.innerWidth <= 1200) {
      setSlidesPerPage(4);
    } else {
      setSlidesPerPage(4);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 550) {
        setSlidesPerPage(1);
      } else if (window.innerWidth <= 800) {
        setSlidesPerPage(2);
      } else if (window.innerWidth <= 980) {
        setSlidesPerPage(3);
      } else if (window.innerWidth <= 1200) {
        setSlidesPerPage(4);
      } else {
        setSlidesPerPage(4);
      }
    });
  });
  return (
    <>
      <BenifitsJumbotron />
      <BenifitsFeature />
      <BenifitstoResources slidesPerPage={slidesPerPage} />
      <BenifitsSafty />
      <BenifitsSmartSolution
        img={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56.896"
            height="54.237"
            viewBox="0 0 56.896 54.237"
          >
            <g
              id="Group_11978"
              data-name="Group 11978"
              transform="translate(-10006.49 10957.865)"
            >
              <path
                id="Path_10050"
                data-name="Path 10050"
                d="M1493.993-93.169c1.424-1.521,2.653-2.953,4.01-4.252a8.753,8.753,0,0,1,6.225-2.578c4.47.007,8.941-.007,13.411.021,2.21.014,3.688,1.183,3.721,2.852.036,1.785-1.457,2.988-3.759,3s-4.615-.083-6.911.06c-.637.04-1.232.763-1.846,1.172.591.434,1.165,1.21,1.777,1.242a40.9,40.9,0,0,0,7.944-.046A8.679,8.679,0,0,0,1523.021-94c2.805-2.715,5.276-5.773,7.991-8.586a4.71,4.71,0,0,1,2.774-1.519,3.408,3.408,0,0,1,2.473,1.472,2.646,2.646,0,0,1-.267,2.459c-3.675,4.382-7.408,8.721-11.27,12.938a8.985,8.985,0,0,1-7.363,2.8c-5.511-.194-11.034-.11-16.551-.018a2.662,2.662,0,0,1-2.766-1.641C1496.81-88.445,1495.418-90.7,1493.993-93.169Z"
                transform="translate(8524.16 -10828.409)"
                fill="currentColor"
              />
              <path
                id="Path_10050_-_Outline"
                data-name="Path 10050 - Outline"
                d="M1517.855-84.185c-.165,0-.335,0-.5-.009-2.245-.079-4.652-.117-7.357-.117-2.967,0-5.914.045-9.181.1h-.1a2.879,2.879,0,0,1-2.889-1.775c-.864-1.642-1.8-3.228-2.787-4.907-.41-.7-.834-1.417-1.258-2.15l-.093-.161.127-.135c.447-.477.877-.949,1.293-1.4.92-1.009,1.79-1.961,2.726-2.857a9,9,0,0,1,6.383-2.647h.016c1.5,0,3.019,0,4.49,0,2.924,0,5.948,0,8.922.019a4.623,4.623,0,0,1,2.828.857,2.862,2.862,0,0,1,1.141,2.239,2.866,2.866,0,0,1-.812,2.107,4.431,4.431,0,0,1-3.2,1.148h-.446c-.571,0-1.151-.005-1.711-.01s-1.138-.01-1.706-.01c-1.217,0-2.153.025-3.034.08a2.39,2.39,0,0,0-1.126.666c-.107.089-.215.18-.326.266.107.092.212.189.315.285.362.334.736.68,1.064.7l.694.037c1.252.067,2.547.137,3.814.137a23.506,23.506,0,0,0,3.388-.217,8.476,8.476,0,0,0,4.319-2.238c1.621-1.569,3.158-3.285,4.645-4.944,1.08-1.205,2.2-2.452,3.34-3.636a5,5,0,0,1,2.942-1.6l.072,0a3.708,3.708,0,0,1,2.621,1.586,2.849,2.849,0,0,1-.284,2.757c-4.293,5.119-7.876,9.233-11.277,12.947A9.022,9.022,0,0,1,1517.855-84.185Zm-7.861-.626c2.711,0,5.123.038,7.374.118a8.706,8.706,0,0,0,7.169-2.714c3.4-3.708,6.975-7.817,11.263-12.93a2.413,2.413,0,0,0,.249-2.161,3.189,3.189,0,0,0-2.252-1.36,4.5,4.5,0,0,0-2.605,1.443c-1.137,1.178-2.251,2.421-3.328,3.623-1.493,1.666-3.036,3.388-4.67,4.97a8.876,8.876,0,0,1-4.595,2.373,24,24,0,0,1-3.46.223c-1.28,0-2.582-.07-3.84-.138l-.693-.037a2.467,2.467,0,0,1-1.378-.83,6.421,6.421,0,0,0-.534-.46l-.288-.211.3-.2a6.665,6.665,0,0,0,.554-.432,2.685,2.685,0,0,1,1.415-.781c.892-.056,1.837-.081,3.065-.081.57,0,1.15.005,1.71.01s1.138.01,1.707.01h.443a3.945,3.945,0,0,0,2.841-1,2.376,2.376,0,0,0,.67-1.748c-.031-1.546-1.427-2.594-3.473-2.607-2.972-.019-6-.019-8.918-.019-1.472,0-2.994,0-4.491,0h-.015a8.513,8.513,0,0,0-6.037,2.508c-.924.885-1.788,1.831-2.7,2.833-.378.414-.767.841-1.171,1.274.392.676.783,1.341,1.163,1.986.992,1.685,1.929,3.276,2.8,4.928a2.41,2.41,0,0,0,2.541,1.507C1504.075-84.766,1507.023-84.811,1509.994-84.811Z"
                transform="translate(8524.16 -10828.409)"
                fill="#fff"
              />
              <path
                id="Path_10052"
                data-name="Path 10052"
                d="M1505.215-175.546c-2.321-2.315-5.741-2.135-8.15.469-1.826,1.974-3.609,3.987-5.494,6.075-2.994-4.065-1.513-8.445-1.623-12.549-.114-4.258.431-8.533.7-13.016l-19.587-5.255-1.968,7.277,12.192,3.348-.088.49h-12.351v15.371l-8.748,7.105c-.811-1.91-2.122-2.091-3.922-.876-1.558,1.052-3.227,1.946-4.884,2.841-1.512.816-1.673,1.868-.845,3.284q4.123,7.05,8.186,14.135c.8,1.4,1.8,1.589,3.125.795,1.558-.93,3.111-1.877,4.72-2.711,1.451-.752,2.667-1.555,1.816-3.567,5.984,0,11.64-.1,17.29.041a9.822,9.822,0,0,0,7.8-2.9q6.428-6.87,12.479-14.09C1507.476-171.2,1507-173.766,1505.215-175.546Zm-33.025-18.768.664-2.385,15.353,4.1-.662,2.4Zm-.651,8.108h15.7c.035.777.065,1.452.1,2.271h-15.8Zm15.813,5.06c-.043.85-.077,1.523-.116,2.287h-15.685v-2.287Zm-15.855,5.02h15.746v2.27H1471.5Zm-10.8,27.748-7.958-13.75,5.054-2.931,7.952,13.771Zm42.917-22.165c-3.675,4.382-7.408,8.721-11.27,12.938a8.984,8.984,0,0,1-7.362,2.8c-3.505-.123-7.015-.134-10.825-.1-1.709.015-3.718.048-5.726.081a2.661,2.661,0,0,1-2.766-1.641c-1.233-2.343-2.625-4.6-4.05-7.066,1.424-1.521,2.653-2.953,4.01-4.252a8.753,8.753,0,0,1,6.226-2.578c4.47.007,8.94-.007,13.41.021,2.21.014,3.688,1.183,3.721,2.852.036,1.785-1.457,2.988-3.76,3s-4.615-.083-6.911.06c-.637.04-1.232.763-1.847,1.172.591.434,1.165,1.21,1.776,1.242a40.9,40.9,0,0,0,7.945-.046,8.679,8.679,0,0,0,4.457-2.306c2.805-2.715,5.276-5.773,7.991-8.586a4.71,4.71,0,0,1,2.774-1.519A3.408,3.408,0,0,1,1503.88-173,2.645,2.645,0,0,1,1503.613-170.544Z"
                transform="translate(8556.539 -10758.043)"
                fill="currentColor"
              />
            </g>
          </svg>
        }
      />
      <BenifitsAppCard />
    </>
  );
}

export function BenifitsResourcesEntry({ img, title, text }) {
  return (
    <div className="benifits__resources__overlay__content__entry">
      <Zoom>
        <div className="benifits__resources__overlay__content__entry__icon">
          {img}
        </div>
      </Zoom>
      <div className="benifits__resources__overlay__content__entry__heading">
        {title}
      </div>
      <div className="benifits__resources__overlay__content__entry__info">
        {text}
      </div>
    </div>
  );
}

import React from "react";

export default function TermsAndConditions() {
  return (
    <div className="privacy__policy">
      <div className="privacy__policy__header">Terms & Conditions</div>
      <div className="privacy__policy__content">
        <div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "8.0pt",
              marginLeft: "0in",
              lineHeight: "107%",
              fontSize: "15px",
            }}
          >
            <span>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: "0in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              This Agreement governs your use of the Readymaid (Pvt) Ltd
              application, website, call centre and technology platform
              (collectively, the “Readymaid (Pvt) Ltd Platform”). Generally, the
              right to operate the Readymaid (Pvt) Ltd Platform is licensed by
              Readymaid (Pvt) Ltd to its relevant Affiliates, and the relevant
              Affiliate in your jurisdiction provides you the right to access
              and use the Readymaid (Pvt) Ltd Platform in your jurisdiction.
            </span>
          </p>
          <p
            style={{
              marginTop: "2em",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: "0in",
              lineHeight: "normal",
              fontSize: "15px",
              textTransform: "uppercase",
              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              PLEASE READ THIS AGREEMENT CAREFULLY BEFORE ACCESSING OR USING THE
              READYMAID (PVT) LTD PLATFORM. IF YOU DO NOT AGREE TO BE BOUND BY
              THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT USE OR
              ACCESS THE READYMAID (PVT) LTD PLATFORM.
            </span>
          </p>
          <p
            style={{
              marginTop: "2em",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: "0in",
              lineHeight: "normal",
              fontSize: "15px",
              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Your access and use of the Readymaid (Pvt) Ltd Platform
              constitutes your agreement to be bound by this Agreement, which
              establishes a contractual relationship between you and Readymaid
              (Pvt) Ltd. Readymaid (Pvt) Ltd may immediately terminate this
              Agreement with respect to you, or generally cease offering or deny
              access to the Readymaid (Pvt) Ltd Platform or any portion thereof,
              at any time for any reason without notice. Supplemental terms may
              apply to certain Services (as defined below), such as policies for
              a particular event, loyalty programme, activity or promotion, and
              such supplemental terms will be disclosed to you in connection
              with the applicable Services. Supplemental terms are in addition
              to, and shall be deemed a part of, this Agreement for the purposes
              of the applicable Services. Supplemental terms shall prevail over
              this Agreement in the event of a conflict with respect to the
              applicable Services.
            </span>
          </p>
          <p
            style={{
              marginTop: "2em",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: "0in",
              lineHeight: "normal",
              fontSize: "15px",
              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd may amend this Agreement from time to time.
              Amendments will be effective upon Readymaid (Pvt) Ltd’s posting of
              an updated Agreement at this location or the amended policies or
              supplemental terms on the applicable Service. Your continued
              access or use of the Readymaid (Pvt) Ltd Platform after such
              posting constitutes your consent to be bound by this Agreement, as
              amended. Our collection and use of personal information in
              connection with the Readymaid (Pvt) Ltd Platform is as provided in
              Readymaid (Pvt) Ltd’s Privacy Policy located at
              https://www.Readymaid.com.pk. Readymaid (Pvt) Ltd may provide to a
              claims processor or an insurer any necessary information
              (including your contact information) if there is a complaint,
              dispute or conflict, which may include an accident, involving you
              and a third party provider and such information or data is
              necessary to resolve the complaint, dispute or conflict.
            </span>
          </p>
          <div
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "8.0pt",
              marginLeft: "0in",
              lineHeight: "107%",
              fontSize: "15px",
            }}
          >
            <ul
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "0in",
              }}
            >
              <li
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: "15px",
                  listStyle: "none",
                }}
              >
                <strong>
                  <span>The Readymaid (Pvt) Ltd Platform</span>
                </strong>
              </li>
            </ul>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",
              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              The Readymaid (Pvt) Ltd Platform provides a digital network which
              functions as a marketplace where persons (“Users”) who seek
              personal household services, the house maintenance, maids
              services, chef, chauffeur, e-payment solutions , and/or can be
              matched with persons , including service providers (“Champions”)
              or third party companies (“Merchant Partners”) who can provide the
              Services. Each User shall create a User account that enables
              access to the Readymaid (Pvt) Ltd Platform. Any decision by a User
              to make use of or accept Services is a decision made in such
              User’s sole discretion. Each Service provided by a Champion or
              other third party provider to a User shall constitute a separate
              agreement between such persons.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",
              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              With respect to goods or services which you can order through the
              Readymaid (Pvt) Ltd Platform, or where Readymaid (Pvt) Ltd is
              supporting your payment to a third party merchant who is not
              present on the Readymaid (Pvt) Ltd Platform or Off-App Merchant
              (as defined below), but which services or goods are provided by
              Merchant Partners, these goods or services are prepared,
              undertaken and provided by the relevant independent Merchant
              Partner.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",
              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Materials posted on the Readymaid (Pvt) Ltd Platform are not
              intended to amount to advice on which reliance should be placed.
              We therefore disclaim all liability and responsibility arising
              from any reliance placed on such materials by any visitor to the
              Readymaid (Pvt) Ltd Platform, or by anyone who may be informed of
              any of its contents.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",
              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We aim to update the Readymaid (Pvt) Ltd Platform regularly and
              may change the content at any time. If the need arises, we may
              suspend access to the Readymaid (Pvt) Ltd Platform and the
              Services or close them indefinitely. Any of the material on the
              Readymaid (Pvt) Ltd Platform or the Services may be out of date at
              any given time, and we are under no obligation to update such
              material.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",
              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              YOU ACKNOWLEDGE THAT NEITHER READYMAID (PVT) LTD NOR ITS
              AFFILIATES PROVIDE SERVICES OR MAINTENANCE THAT ALL SUCH SERVICES
              ARE PROVIDED BY INDEPENDENT THIRD PARTY CONTRACTORS WHO ARE NOT
              EMPLOYED BY READYMAID (PVT) LTD OR ANY OF ITS AFFILIATES.
            </span>
          </p>
          <div
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "8.0pt",
              marginLeft: "0in",
              lineHeight: "107%",
              fontSize: "15px",
            }}
          >
            <ul
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "0in",
                listStyle: "none",
              }}
            >
              <li
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: "15px",
                  listStyle: "none",
                }}
              >
                <strong>
                  <span>License</span>
                </strong>
              </li>
            </ul>
          </div>
          {/* <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <em>
              <span
                style={{
                  color: "black",
                  background: "white",
                }}
              >
                Information You Provide to Us
              </span>
            </em>
          </p> */}
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Subject to your compliance with this Agreement, Readymaid (Pvt)
              Ltd grants you a limited, non-exclusive, non-sub-licensable,
              revocable, non-transferrable license to: (i) access and use the
              Readymaid (Pvt) Ltd Platform on your personal device solely in
              connection with your use of the Readymaid (Pvt) Ltd Platform; and
              (ii) access and use any content, information and related materials
              that may be made available through the Readymaid (Pvt) Ltd
              Platform, in each case solely for your personal, non-commercial
              use. Any rights not expressly granted herein are reserved by
              Readymaid (Pvt) Ltd and Readymaid (Pvt) Ltd’s licensors.
            </span>
          </p>
          {/* <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Please note that we do not knowingly collect, keep or maintain
              personal information from children under the age of 18 through the
              Services, as we require that all users represent to us that they
              are at least 18 years old.
            </span>
          </p> */}
          {/* <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <em>
              <span
                style={{
                  color: "black",
                  background: "white",
                }}
              >
                Information We Collect Through Your Use of Our Services
              </span>
            </em>
          </p> */}
          {/* <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              When you use our Services, we collect personal information (i.e.
              information that could be used to contact you directly such as
              full name, postal address, phone number, credit/debit card
              information, or email address) (“Personal Information”) and
              demographic information (i.e. information that you submit, or that
              we collect, that is not personal information; this may include,
              but is not limited to, post code, hometown, gender, username,
              device information, including the type of mobile device you use, a
              unique device identifier (for example, your device's IMEI number,
              the MAC address of the device's wireless network interface, or the
              mobile phone number used by the device), mobile network
              information, your mobile operating system, the type of mobile
              browser you use, time zone setting, device location, IP address,
              SMS data, transaction information, age/birth date, browsing
              history information, searching history information, and
              registration history information) (“Demographic Information” and,
              together with Personal Information, “Personal Data”).
            </span>
          </p> */}
          {/* <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We also use GPS technology to determine your current location.
              Some of our location-enabled Services require your personal data
              for the feature to work. If you wish to use the particular
              feature, you will be asked to consent to your data being used for
              this purpose.
            </span>
          </p> */}
          {/* <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <em>
              <span
                style={{
                  color: "black",
                  background: "white",
                }}
              >
                Information We Collect From Other Sources
              </span>
            </em>
          </p> */}
          {/* <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We may also receive information from other sources and combine
              that with Personal Data we receive from you or collect through our
              Services. For example:
            </span>
          </p> */}
          {/* <ul style={{ listStyleType: "undefined", marginLeft: "0.5in" }}>
            <li>
              <span style={{ background: "white" }}>
                If you choose to link, create, or log in to your Readymaid (Pvt)
                Ltd. account with a payment provider or social media service
                (e.g., Facebook), or if you engage with a separate app or
                website that uses our API (or whose API we use), we may receive
                information about you or your connections from that site or app.
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                If your employer uses one of our enterprise solutions, we may
                receive information about you from your employer.
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                If your employer uses one of our enterprise solutions, we may
                receive information about you from your employer.
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                If you also interact with our Services in another capacity, for
                instance as a Captain, we may combine or associate that
                information with Personal Data we have collected from you in
                your capacity as a User or rider.
              </span>
            </li>
          </ul> */}
          <p
            style={{
              marginTop: "5.0pt",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: "0in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                background: "white",
              }}
            >
              &nbsp;
            </span>
          </p>
          <div
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "8.0pt",
              marginLeft: "0in",
              lineHeight: "107%",
              fontSize: "15px",
            }}
          >
            <ul
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "0in",
              }}
            >
              <li
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: "15px",
                  listStyle: "none",
                }}
              >
                <strong>
                  <span>Third Party Services and Content.</span>
                </strong>
              </li>
            </ul>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              The Readymaid (Pvt) Ltd Platform may be made available or accessed
              in connection with third party services and content (including
              advertising). Readymaid (Pvt) Ltd does not endorse such
              third-party services and content and you agree that, in no event,
              will Readymaid (Pvt) Ltd be responsible or liable for any products
              or services of such third party providers.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              When purchasing goods or services provided by a Merchant Partner,
              you agree and understand that you are doing so subject to the
              relevant Merchant Partner’s terms and conditions and at your own
              risk. You agree that Readymaid (Pvt) Ltd will not be liable for:
              (i) any act or omission of a Merchant Partner and/or (ii) any
              damage or loss that arises in connection with the ordering,
              purchase and/or provision of a Merchant Partner’s goods or
              service.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You further understand that to enable a Merchant Partner to
              provide you with relevant goods or services, Readymaid (Pvt) Ltd
              may be required to share personal information in the manner
              described in Readymaid (Pvt) Ltd’s Privacy Notice.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              For additional terms in relation to the Merchant Checkout Product
              available through Readymaid (Pvt) Ltd Pay please see below.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Additionally, Apple Inc., Google, Inc., and/or their applicable
              international subsidiaries and affiliates will be third-party
              beneficiaries to this contract if you access the Readymaid (Pvt)
              Ltd Platform using applications developed for Apple iOS or
              Android-powered mobile devices, respectively. These third party
              beneficiaries are not parties to this contract and are not
              responsible for the provision or support of the Readymaid (Pvt)
              Ltd Platform in any manner. Your access to the Readymaid (Pvt) Ltd
              Platform using these services or applications is subject to terms
              set forth in the applicable third party beneficiary’s terms of
              service.
            </span>
          </p>
          {/* <ul style={{ listStyleType: "undefined", marginLeft: "0.5in" }}>
            <li>
              <span style={{ background: "white" }}>
                Provide, maintain, and improve our Services, including, for
                example, to facilitate payments, send receipts, provide products
                and services you request (and send related information), develop
                new features, provide customer support to Users and Champions,
                develop safety features, authenticate users, and send product
                updates and administrative messages;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Perform internal administration and operations, including, for
                example, to prevent fraud and abuse of our Services; to
                troubleshoot software bugs and operational problems; to conduct
                data analysis, testing, and research; and to monitor and analyze
                usage and activity trends;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Send or facilitate communications (i) between you and a Captain,
                such as estimated times of arrival (ETAs), or (ii) between you
                and a contact of yours at your direction in connection with your
                use of certain features, such as referrals or invites;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Send you communications we think will be of interest to you,
                including information about products, services, promotions,
                news, and events of Readymaid (Pvt) Ltd. and other companies,
                where permissible and according to local applicable laws; and to
                process contest, sweepstake, or other promotion entries and
                fulfill any related awards;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Notify you about changes to our Services;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Allow you to participate in interactive features of our
                Services;
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Keep our Services safe and secure; and
              </span>
            </li>
            <li>
              <span style={{ background: "white" }}>
                Personalize and improve the Services, including to provide or
                recommend features, content, social connections, referrals, and
                advertisements.
              </span>
            </li>
          </ul> */}
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Readymaid (Pvt) Ltd Platform Ownership.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              The Readymaid (Pvt) Ltd Platform and all rights therein are and
              shall remain Readymaid (Pvt) Ltd’s property or the property of
              Readymaid (Pvt) Ltd’s licensors. Neither this Agreement nor your
              use of the Readymaid (Pvt) Ltd Platform convey or grant to you any
              rights: (i) in or related to the Readymaid (Pvt) Ltd Platform
              except for the limited license granted above; or (ii) to use or
              reference in any manner Readymaid (Pvt) Ltd’s company names,
              logos, product and service names, trademarks or services marks or
              those of Readymaid (Pvt) Ltd’s licensors.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Provision of the Services.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You acknowledge that portions of the Services may be made
              available under Readymaid (Pvt) Ltd’s various request options,
              including maintenance & house services etc.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You also acknowledge that the Services may be made available under
              such brands or request options by or in connection with: (i)
              certain Affiliates; or (ii) independent third party contractors,
              including service providers or holders of similar experience,
              authorizations or licenses.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              It is at Readymaid (Pvt) Ltd’s discretion which brands or request
              options are made available to you.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Use of the Readymaid (Pvt) Ltd Platform</span>
              </strong>
            </li>
          </ul>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>User Accounts</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              In order to use most aspects of the Readymaid (Pvt) Ltd Platform,
              you must register for and maintain an active personal User account
              (“Account”). You must be at least 18 years of age to obtain an
              Account. Account registration requires you to submit to Readymaid
              (Pvt) Ltd certain personal information, such as your name,
              address, mobile phone number, gender and age. You agree to
              maintain accurate, complete, and up-to-date information in your
              Account. Your failure to maintain accurate, complete, and
              up-to-date Account information may result in your inability to
              access and use the Readymaid (Pvt) Ltd Platform, including your
              ability to request access to your personal information or to opt
              in or out of marketing preferences, or Readymaid (Pvt) Ltd’s
              termination of this Agreement with you.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You agree to maintain the security and secrecy of your Account
              username and password at all times. You agree and understand that
              you are responsible for all activity that occurs under your
              Account, even as a result of loss, damage or theft of the device
              through which you access the Readymaid (Pvt) Ltd Platform.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Unless otherwise permitted by Readymaid (Pvt) Ltd in writing, you
              may only possess one Account.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>User Requirements and Conduct.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              The Readymaid (Pvt) Ltd Platform is not available for use by
              persons under the age of 18 and may only be used by individuals
              who can form legally binding contracts under applicable law. You
              may not authorize third parties to use your Account, and you may
              not allow persons under the age of 18 to receive services from
              Champions unless they are accompanied by you or another adult.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You may not assign or otherwise transfer your Account to any other
              person or entity. You agree to comply with all applicable laws
              when using the Readymaid (Pvt) Ltd Platform, and you may only use
              the Readymaid (Pvt) Ltd Platform for lawful purposes. You shall
              not, for the avoidance of doubt, use the Readymaid (Pvt) Ltd
              Platform for illegal services. You will not, in your use of the
              Readymaid (Pvt) Ltd Platform, cause nuisance, annoyance,
              inconvenience, or property damage, whether to a Champion, Service
              Provider, other third party provider or any other party. In
              certain instances, you may be asked to provide proof of identity
              to access or use the Readymaid (Pvt) Ltd Platform, and you agree
              that you may be denied access to or use of the Readymaid (Pvt) Ltd
              Platform if you refuse to provide proof of identity. Failure to
              comply with the terms of this section or of section 5 may result
              in our taking certain actions against you, including but not
              limited to: (i) immediate, temporary or permanent withdrawal of
              your right to use the Readymaid (Pvt) Ltd Platform (ii) legal
              action against you including proceedings for reimbursement of all
              costs on an (including, but not limited to, reasonable
              administrative and legal costs) resulting from the breach (iii)
              disclosure of such information to law enforcement authorities as
              we feel is necessary and/or (iv) immediate, temporary or permanent
              removal of any posting or material uploaded by you to our Service.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>
                  Promotions, Bundles, Credits, Mobile Top Ups, and Referral
                  Programs.
                </span>
              </strong>
            </li>
          </ul>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Promo Codes</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd, in its sole discretion, may make promotions
              available to any User or potential User. These promotions, unless
              made to you, shall have no bearing whatsoever on your Agreement or
              relationship with Readymaid (Pvt) Ltd. Promotions will in many
              cases be made available to you via alpha numeric codes (“Promo
              Codes”). Readymaid (Pvt) Ltd reserves the right to withhold or
              deduct credits or benefits obtained through a promotion in the
              event that Readymaid (Pvt) Ltd determines or believes that the
              redemption of the promotion or receipt of the credit or benefit
              was in error, fraudulent, illegal, or in violation of the
              applicable promotion terms or this Agreement.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You agree that Promo Codes: (i) must be used for the intended
              audience and purpose, and in a lawful manner; (ii) may not be
              duplicated, sold or transferred in any manner, or made available
              to the general public (whether posted to a public form or
              otherwise), unless expressly permitted by Readymaid (Pvt) Ltd;
              (iii) may be disabled by Readymaid (Pvt) Ltd at any time for any
              reason without liability to Readymaid (Pvt) Ltd; (iv) may only be
              used pursuant to the specific terms that Readymaid (Pvt) Ltd
              establishes for such Promo Code; (v) are not valid for cash; (vi)
              may expire prior to your use and (vii) Readymaid (Pvt) Ltd may
              establish additional terms relating to specific promotions.
              Readymaid (Pvt) Ltd reserves the right to withhold or deduct
              credits or other features or benefits obtained through the use of
              Promo Codes by you or any other user in the event that Readymaid
              (Pvt) Ltd determines or believes that the use or redemption of the
              Promo Code was in error, fraudulent, illegal, or in violation of
              the applicable Promo Code terms or this Agreement.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You agree that Promo Codes: (i) must be used for the intended
              audience and purpose, and in a lawful manner; (ii) may not be
              duplicated, sold or transferred in any manner, or made available
              to the general public (whether posted to a public form or
              otherwise), unless expressly permitted by Readymaid (Pvt) Ltd;
              (iii) may be disabled by Readymaid (Pvt) Ltd at any time for any
              reason without liability to Readymaid (Pvt) Ltd; (iv) may only be
              used pursuant to the specific terms that Readymaid (Pvt) Ltd
              establishes for such Promo Code; (v) are not valid for cash; (vi)
              may expire prior to your use and (vii) Readymaid (Pvt) Ltd may
              establish additional terms relating to specific promotions.
              Readymaid (Pvt) Ltd reserves the right to withhold or deduct
              credits or other features or benefits obtained through the use of
              Promo Codes by you or any other user in the event that Readymaid
              (Pvt) Ltd determines or believes that the use or redemption of the
              Promo Code was in error, fraudulent, illegal, or in violation of
              the applicable Promo Code terms or this Agreement.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You may be entitled to purchase bundles of Readymaid (Pvt) Ltd
              Credit(defined below) (“Readymaid (Pvt) Ltd Bundles”) or you may
              receive service promotions(“Service Promotions”) that you can
              apply toward payment of certain services provided by Readymaid
              (Pvt) Ltd or fees charged by Readymaid (Pvt) Ltd in relation to
              the Services.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd Bundles, Readymaid (Pvt) Ltd Credits, and
              Service Promotions are only valid for use on the Readymaid (Pvt)
              Ltd Platform, and are not transferable or redeemable for cash and
              may only be used for certain Services as notified to you.
              Readymaid (Pvt) Ltd Bundles, and Service Promotions cannot be
              combined, and if the cost of your ride exceeds the applicable
              credit or discount value we will charge your payment method on
              file for the outstanding cost of the trip.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Additional restrictions on Readymaid (Pvt) Ltd Bundles, Readymaid
              (Pvt) Ltd Credits, and Service Promotions may apply as
              communicated to you in a relevant promotion or specific terms.
              Readymaid (Pvt) Ltd may cancel, or vary the terms, relating to any
              Readymaid (Pvt) Ltd Bundles, Readymaid (Pvt) Ltd Credits, or
              Service Promotions at any time in its sole discretion
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              From time to time, Readymaid (Pvt) Ltd may offer you incentives to
              refer new Users to the Readymaid (Pvt) Ltd community (the
              “Referral Program”). These incentives may come in the form of
              Readymaid (Pvt) Ltd Credits, and/or Service Promotions, and
              Readymaid (Pvt) Ltd may set or change the incentive types,
              amounts, terms, restrictions, and qualification requirements for
              any incentives in its sole discretion.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Bundles</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You may be entitled to purchase bundles of Readymaid (Pvt) Ltd
              Credit(defined below) (“Readymaid (Pvt) Ltd Bundles”) or you may
              receive service promotions(“Service Promotions”) that you can
              apply toward payment of certain services provided by Readymaid
              (Pvt) Ltd or fees charged by Readymaid (Pvt) Ltd in relation to
              the Services.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd Bundles, Readymaid (Pvt) Ltd Credits, and
              Service Promotions are only valid for use on the Readymaid (Pvt)
              Ltd Platform, and are not transferable or redeemable for cash and
              may only be used for certain Services as notified to you.
              Readymaid (Pvt) Ltd Bundles, and Service Promotions cannot be
              combined, and if the cost of your ride exceeds the applicable
              credit or discount value we will charge your payment method on
              file for the outstanding cost of the trip.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Additional restrictions on Readymaid (Pvt) Ltd Bundles, Readymaid
              (Pvt) Ltd Credits, and Service Promotions may apply as
              communicated to you in a relevant promotion or specific terms.
              Readymaid (Pvt) Ltd may cancel, or vary the terms, relating to any
              Readymaid (Pvt) Ltd Bundles, Readymaid (Pvt) Ltd Credits, or
              Service Promotions at any time in its sole discretion
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              From time to time, Readymaid (Pvt) Ltd may offer you incentives to
              refer new Users to the Readymaid (Pvt) Ltd community (the
              “Referral Program”). These incentives may come in the form of
              Readymaid (Pvt) Ltd Credits, and/or Service Promotions, and
              Readymaid (Pvt) Ltd may set or change the incentive types,
              amounts, terms, restrictions, and qualification requirements for
              any incentives in its sole discretion.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Readymaid (Pvt) LtdPay</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              As part of your User Account, Readymaid (Pvt) Ltd may provide you
              with access to certain payment features in, or in connection with,
              the Readymaid (Pvt) Ltd application (“RPay/Readymaid (Pvt)
              LtdPay”) including: (i) managing payment for Services; (ii) adding
              and storing Readymaid (Pvt) Ltd Credits (defined below); (iii)
              transfering Readymaid (Pvt) Ltd Credit to or receiving Readymaid
              (Pvt) Ltd Credit from other Users, against payment of any
              applicable Readymaid (Pvt) Ltd Fees and subject to any limits on
              the number of transfers or the amount of Readymaid (Pvt) Ltd
              Credit you can send and/or receive, which Fees and limits may be
              modified by Readymaid (Pvt) Ltd from time to time; and/or (iv)
              transfering cash to another User’s local bank account (“Cash
              Transfers”); and/or (v) paying for products/goods on third party
              merchant sites not present on, or affiliated with the Readymaid
              (Pvt) Ltd Platform (“Off-App Merchants”) (“RPay Checkout”)
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              In relation to those features listed above, and made available
              through your User Account, the following phrases have the meanings
              set out below.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Please note that not all features are available in each market and
              may be subject to change.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              “Readymaid (Pvt) Ltd Credit” means the prepaid balance which is
              represented electronically, denominated in your local currency and
              attributed to your User Account, and which;
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              (i) is credited to your User Account by Readymaid (Pvt) Ltd upon
              collection or receipt of funds;
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              (ii) may be reloaded with additional value by you (subject to any
              limits imposed by Readymaid (Pvt) Ltd or by law and as may be
              notified to you from time to time);
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              (iii) may be redeemed or applied as a payment method where
              Readymaid (Pvt) Ltd and/or a regulated financial instution partner
              will satisfy and settle the payment obligation to the relevant
              third party provider up to the Readymaid (Pvt) Ltd Credit balance
              reflected in your User account.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              (iv) may not be redeemable for cash
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              “Payment Method” has the meaning set out in ‘Loading Readymaid
              (Pvt) Ltd Credit’ below.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Loading Readymaid (Pvt) Ltd Credit</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              As part of your User Account you may load Readymaid (Pvt) Ltd
              Credit by any one or more of the following payment methods (where
              offered by Readymaid (Pvt) Ltd from time to time):
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              (i) by cash paid to authorised agents, third party providers or
              Champion (where enabled by Readymaid (Pvt) Ltd and permitted by
              law);
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              (ii) debit card;
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              (iii) credit card;
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              (iv) gift card (where available and permitted by law); or
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              (v) any other payment method made available by Readymaid (Pvt) Ltd
              from time to time
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              (each a ‘Payment Method’)
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You can reload Readymaid (Pvt) Ltd Credits at any time before the
              start of a Service (e.g. booking a service, ordering a resource)
              by clicking on the Payment button and then the ‘+’ ic on under the
              ‘available credit’ banner in the Payment section of your User
              Account’. If you want to add a new Payment Method you must do so
              before using a Service.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You may also be able to add alternative payment methods to reload
              Readymaid (Pvt) Ltd Credits through the Payment button.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              By selecting a particular Payment Method, you acknowledge that you
              are agreeing to the terms of service of Readymaid (Pvt) Ltd’s
              payment processing partners and the financial institution or
              payment service provider who has issued your selected Payment
              Method. You further agree that you will bear the cost or changes
              of any fees imposed by such payment processing partners or
              financial institutions in connection with your selected Payment
              Method.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd may in its sole and absolute discretion choose
              to refuse a request to reload Readymaid (Pvt) Ltd Credit,
              including (without limitation) where reloading would result in the
              aggregate value stored in your Account exceeding any value
              permitted by prevailing laws and regulations
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd (in its sole and absolute discretion) may, at
              any time (including at the time of completing a transaction),
              notify you via the Application that it has imposed a maximum
              amount by which you may reload or inform you that you have reached
              the limit on the number of times you may reload credits within a
              specified period.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              By loading Readymaid (Pvt) Ltd Credit you acknowledge and agree
              that you are not depositing cash or money with Readymaid (Pvt)
              Ltd.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Peer to Peer - Credit Transfer</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              As part of your User Account you may transfer Readymaid (Pvt) Ltd
              Credits from your Account to the Account of another User. When
              transferring Readymaid (Pvt) Ltd Credit balance to another User,
              the amount transferred and the applicable Readymaid (Pvt) Ltd Fees
              (if any) will be deducted from your User account balance and will
              be transferred and added to the Readymaid (Pvt) Ltd Credit balance
              of the other User. Once the request for the transfer of Readymaid
              (Pvt) Ltd Credit is completed, the transfer cannot be cancelled or
              otherwise reversed. You agree that in providing you with this
              feature, Readymaid (Pvt) Ltd is merely enabling the transfer of
              Readymaid (Pvt) Ltd Credit balance. Readymaid (Pvt) Ltd is not
              responsible for the actions of the individual Users, including the
              reason for which Readymaid (Pvt) Ltd Credit is transferred by or
              to you
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Peer to Peer - Cash Transfers</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              As part of your User Account, you may have access to ‘Cash
              Transfers’. Cash Transfers are subject to applicable law at all
              times and may not be permitted in some jurisdictions. Even where
              Cash Transfers are permitted and available they are restricted to
              Cash Transfers to other Users that are resident in the same
              jurisdiction as you and that hold a bank account in that same
              jurisdiction (“Recipient Users”).
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Cash Transfers are intended to be used in the context of small
              ‘social payments’ i.e. repaying a friend for a meal out, the cost
              of a booked service, a cinema ticket and so on. Cash Transfers are
              not intended to be used for own account transfers, avoiding
              charges for cash withdrawal, or artificially inflating benefits
              from credit/debit cards. You acknowledge and agree that Cash
              Transfers must not be used for any prohibited, fraudulent, illegal
              or improper purpose.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Cash Transfers by you to Recipient Users are subject to applicable
              law and limits in respect of the number, frequency and amounts of
              relevant transfers as well as the location of Recipient Users.
              Such limits are at the sole discretion of Readymaid (Pvt) Ltd and
              Readymaid (Pvt) Ltd may modify such limits from time to time
              without amending this Agreement. You will be notified if you have
              exceeded the limits and the Cash Transfer will be blocked and the
              amount refunded – requests will not be partially paid. Cash
              Transfers may not be permitted or available in your jurisdiction.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              If we identify an unusual or potentially high risk Cash Transfer
              we may need to review it more closely before allowing it to go
              ahead. Cash Transfers which do not comply with these terms and
              conditions or are suspected to be in breach of relevant laws may
              be blocked, held, or refunded (as appropriate). This may mean that
              your Cash Transfer is delayed or may be rejected. In addition, we
              may suspend or restrict your use of RPay or your User Account as a
              whole if we suspect any: security issues in relation to your User
              Account; unauthorised or fraudulent use of the features made
              available to you through RPay ; or breach of these terms and
              conditions
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Upon receiving a request in relation to a permissible Cash
              Transfer from you as transferor to a Recipient User as a
              transferee, Readymaid (Pvt) Ltd shall facilitate such transfer as
              a facilitator of social payments. The amount you elect to transfer
              will be deducted from your card on file by Readymaid (Pvt) Ltd
              subject to any charges incurred by your card issuing bank. The
              Recipient User you have identified will have the option, once
              notification of the transfer has been received by the Recipient
              User, to either receive the Cash Transfer as cash in a permissible
              bank account held in the name of the Recipient User or to receive
              Readymaid (Pvt) Ltd Credit in lieu of such cash amount.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              If you are a Recipient User and elect to receive the Cash Transfer
              as cash in a permissible bank account held by you, you will be
              prompted to include the name of your bank, the name on your bank
              account and the IBAN number only. Readymaid (Pvt) Ltd will then
              facilitate the transfer of the relevant amount to such permissible
              bank account held by you. Readymaid (Pvt) Ltd will not ask for you
              to provide any financially sensitive information. You must only
              add the IBAN of your own, personal account. You must not add the
              details of a business account or an account of a third party. If,
              as a Recipient User, you do not accept the transferred amount
              within a certain number of days of receipt (and such number of
              days may vary from time to time), the relevant cash amount will be
              returned to the account of the transferor.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We will hold the amount corresponding with the Credit Transfer for
              as short a time as possible for the sole purpose of facilitating
              the Cash Transfer. We endeavor to credit the bank account of the
              Recipient User as soon as practically achievable after we receive
              the amount corresponding with the Credit Transfer. We are not
              responsible for the time it takes the bank of the Recipient User
              to credit the amount of the Cash Transfer your bank account and
              Readymaid (Pvt) Ltd shall not be liable in any way for the same
              and we reserve the right to decline any instruction to effect a
              Credit Transfer or when accepted, to complete a Credit Transfer.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You are responsible for the accuracy of the information you add or
              include on the Readymaid (Pvt) Ltd app. We may ask you for
              specific additional information that we believe to be necessary to
              give effect to our services to you. The provision of false or
              incomplete information or not providing information when asked may
              lead to the termination of your Readymaid (Pvt) Ltd profile and
              prosecution. All activities on the Readymaid (Pvt) Ltd app
              (including activities in connection with Credit Transfers) are
              regarded as activities by you and no other Users or persons and
              you shall not transact in connection with Credit Transfers for or
              on behalf of any other person. You acknowledge and agree that
              information about you, and the products we make available to you
              may be provided by us from time to time to regulatory,
              governmental authorities or other third parties in line with our
              privacy policy, where we are required by law, or we determine that
              such disclosure may help combat fraud, money laundering or other
              criminal activity.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You agree that, in relation to the above-mentioned cash transfer
              feature, Readymaid (Pvt) Ltd is acting only as a facilitator of
              social payments, is not responsible or liable for the actions of
              individual Users (including the reasons for the Cash Transfer) and
              that Readymaid (Pvt) Ltd does not operate a deposit-taking
              business in any manner nor is it otherwise presently licensed as a
              financial institution.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You agree to indemnify and hold Readymaid (Pvt) Ltd, its
              Affiliates, and other partners and their officers, directors,
              employees and agents harmless from any and all claims, demands,
              losses, liabilities, and expenses (including attorneys’ fees)
              arising out of or in connection with any improper or illegal use
              of RPay, your User Account, or the Cash Transfer product and/or
              any breach of these terms and conditions
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>RPay Checkout</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Through your use of other third party websites and applications
              you may have access to the RPay Checkout product. RPay Checkout
              makes it easier for Off-App Merchants to collect payments for
              services or products that they provide to you. RPay Checkout
              enables you to use your existing card-on-file to complete your
              purchase on the Off-App Merchant site.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Using RPay Checkout does not change anything about the
              relationship you have with that Off-App Merchant or your card
              provider or bank in terms of your payment for the goods, services,
              or products on the Off-App Merchants or with your bank or card
              provider.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              If you elect to use the RPay Checkout where an Off-App Merchant
              has this feature enabled you will be redirected to your existing
              Payment Methods. Your available Payment Methods will be limited to
              your cards-on-file only and you will not be able to use Readymaid
              (Pvt) Ltd Credits in relation to this feature. In order to enable
              the processing of your payment for goods or services offered by
              the Off-App Merchant, Readymaid (Pvt) Ltd is using the information
              you store through the Readymaid (Pvt) Ltd Platform (or with
              permitted third party providers (where relevant)) to make payments
              for Services. The terms for storage of your information are
              included in our Privacy Policy - which you can see here.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              RPay Checkout including information made available, as well as any
              content, software, materials or other functions offered to you are
              provided ‘as is’ and ‘as available’. Readymaid (Pvt) Ltd does not
              warrant that availability of RPay Checkout shall be uninterrupted
              and error free. Readymaid (Pvt) Ltd shall not be responsible for
              service interruptions or failures that may affect, receipt,
              processing, or completion of payment unless such interruption has
              been caused solely by Readymaid (Pvt) Ltd. In addition, Readymaid
              (Pvt) Ltd makes no representation or warranty in relation to your
              use of RPay Checkout and, to the fullest extent permissible by
              law, Readymaid (Pvt) Ltd disclaims any liability with respect to
              fitness for purpose, merchantability, and non-infringement.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You are responsible for the purchase you make using RPay Checkout
              and the Off-App Merchant is responsible for supplying you with the
              goods, services, or products you have elected to purchase through
              their site. If there is an issue with the goods, services, or
              products you have purchased using RPay Checkout please get in
              touch, directly, with the Off-App Merchant. Any returns,
              exchanges, refunds are subject to the policy of the merchant you
              have purchased from. Readymaid (Pvt) Ltd is not responsible for
              processing or managing refunds, chargebacks or other issues
              relating to your purchase. Readymaid (Pvt) Ltd is not subject to
              and does not have control over the policies of any Off-App
              Merchants and is not responsible for the actions of Off-App
              Merchants.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Loyalty Program.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              By becoming a User you are part of the Readymaid (Pvt) Ltd Loyalty
              Program (the “Loyalty Program ”) in those markets where the
              Loyalty Program is available. Users will accrue points through
              using the Readymaid (Pvt) Ltd Platform to access Services provided
              by the third party service providers and may have access to a
              number of services, ways to support their local community, and
              benefits (“Rewards”) depending upon a given tier status (and
              relevant availability in any given market).
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Users can earn or maintain tier status based on their usage of the
              Readymaid (Pvt) Ltd Platform in any calendar month. Once a User
              attains a tier level, it will remain in effect for 2 consecutive
              months. With respect to the expiry of points earned pursuant to
              the Loyalty Program, each calendar year consists of 2 reward
              periods: from January 1st to June 30th and from July 1st to
              December 31st (each a “Reward Period”) and any points earned in
              one Reward Period will expire automatically at the end of the
              following Reward Period.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Rewards are offered at the sole discretion of Readymaid (Pvt) Ltd
              and may be varied from time to time. Rewards and participating
              partners may differ from market to market and may be added or
              removed at the sole discretion of Readymaid (Pvt) Ltd. You may
              redeem points in exchange for Rewards or Donations (as applicable)
              at any time as indicated on the App. Where you redeem points
              Readymaid (Pvt) Ltd shall settle the equivalent or agreed amount
              (if applicable) with the participating partner and cancel its
              liability to you for the same. By redeeming Rewards you confirm
              that you agree to the terms of the Loyalty Program.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd has the right to add, change, limit, modify or
              cancel Loyalty Program rules, regulations, rules for earning and
              redeeming Rewards points, rewards, reward levels, redemption
              levels, processes, benefits, tier statuses, and Loyalty Program
              partners without notice, even though such changes may affect the
              member’s tier status, the value of points already accumulated, the
              ability to use accumulated points, or the ability to obtain
              certain rewards.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd may, among other things: (1) increase or
              decrease the number of points received for a given action or
              amount spent or the number of points required for a reward; (2)
              withdraw, limit, modify, or cancel any reward; (3) add blackout
              dates, limit availability for any reward, or otherwise restrict
              the continued availability of rewards; (4) change program benefits
              served by Rewards or its partners, conditions of participation,
              rules for earning, redeeming, retaining, or forfeiting points, or
              rules governing the use of rewards; (5) change or cancel rewards;
              and (6) change the rules governing the tier statuses, including
              but not limited to: rules to reach a tier status, duration and
              expiry rules of the tier status, benefits granted by reaching a
              given tier status.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd makes no warranties or representations, either
              expressed or implied, and expressly disclaim all liability
              (including consequential damages) with respect to type,
              timeliness, cost, quality or fitness of goods or services provided
              through the Loyalty Program.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              If any participating Rewards partner improperly denies a User an
              accrual or benefit, the liability of Readymaid (Pvt) Ltd shall be
              limited to the equivalent value, in Rewards points, of that
              accrual or benefit as determined solely by Readymaid (Pvt) Ltd.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              The Loyalty Program has no predetermined termination date and may
              continue until such time as Readymaid (Pvt) Ltd decides to
              terminate the Loyalty Program, at any time, with or without
              notice. If the Loyalty Program is terminated, all unredeemed
              points or tier status benefits shall be forfeited without any
              obligation or liability, no award claims or tier status benefits
              shall be honoured after the conclusion of any notice period.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd reserves the right to cancel a User’s access
              to the Loyalty Program and revoke any and all unredeemed Readymaid
              (Pvt) Ltd Rewards points collected by any Member who appears to be
              using the Loyalty Program in a manner inconsistent with the terms
              or intent of the Loyalty Program or any portion thereof. For
              reasons that include, but are not limited to: 1) violation of
              these terms and conditions; 2) misrepresentation of any
              information or any misuse of this Loyalty Program; 3) violation of
              any national, state or local law or regulation in connection with
              the use of this Loyalty Program; 4) commission of fraud or abuse
              involving any portion of this Loyalty Program ; or 5) action, in
              any other way, to the detriment of the Loyalty Program or any of
              its stakeholders; all as may be determined by Readymaid (Pvt) Ltd
              in its sole discretion.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Notwithstanding the foregoing, Loyalty Program violations, fraud
              or abuse in relation to rewards points and credit, tier status or
              reward usage is subject to appropriate administrative and/or legal
              action by appropriate governmental authorities and/or by Readymaid
              (Pvt) Ltd including, without limitation, the forfeiture of all
              point transfers, rewards, tier status or award issued pursuant to
              point redemptions and any accrued points in your account, as well
              as cancellation of the account.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              The earning and redeeming of Rewards points are subject to all
              applicable local laws and regulations and are offered in good
              faith, however they may not be available or are subject to change
              if prohibited or restricted by applicable law or regulation.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Separate terms and conditions may apply to your reservation,
              purchase of goods and services, or rewards that you select. Please
              read these separate terms and conditions carefully. You agree to
              abide by the terms and conditions of purchase imposed by any
              supplier with whom you elect to deal. You understand that any
              violation of any such supplier's rules and restrictions may result
              in your being denied access to the applicable product or services,
              in your forfeiting any monies paid for such product or service.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Readymaid (Pvt) Ltd Plus Program</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              As a User, you may be eligible to enroll in the Readymaid (Pvt)
              Ltd Plus Program (“Plus Program”), which is a subscription program
              of one or more subscription plans (each a “Subscription Plan”)
              through which you may access benefits (“Benefits”) that you can
              avail through the Readymaid (Pvt) Ltd Platform and/or when
              purchasing goods or services from third parties (“Plus Partners”).
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              The Benefits will either be automatically applied when making a
              transaction through the Readymaid (Pvt) Ltd Platform or you may be
              required to carry out extra steps to make use of a Benefit, which
              steps will be communicated to you by Readymaid (Pvt) Ltd from time
              to time.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              The availability of Benefits is subject to all applicable local
              laws and regulations and are offered in good faith, however they
              may not be available and are subject to change if prohibited or
              restricted by applicable law or regulation.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Each Benefit may have additional terms or limitations. When
              redeeming Benefits made available by a Plus Partner, separate
              terms and conditions may apply to your purchase of goods and
              services from the Plus Partner. Please read these separate terms
              and conditions carefully. You agree to abide by the terms and
              conditions of purchase imposed by any Plus Partner you elect to
              deal with. You understand that any violation of any such Benefits
              Partner's rules and restrictions may result in your being denied
              access to the applicable product or services, in your forfeiting
              any monies paid for such product or service.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You may be given an option to enroll in the Plus Program through
              in-app messaging or other digital channels (“Offer”). Each Offer
              may include important corresponding terms, such as the type of
              Benefits included in a Subscription Plan, the cost of the Renewal
              Subscription (“the Subscription Charge”) and the billing interval
              (“Billing Cycle”). It is your responsibility to ensure that you
              have reviewed the Offer terms before you accept and purchase a
              Subscription.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              When you buy a Subscription Plan, you will be charged the
              Subscription Charge for your first Billing Cycle on the date of
              purchase, which activates the Benefits identified in the Offer.
              Readymaid (Pvt) Ltd may elect to provide you with a free trial to
              the Plus Program or other promotional subscriptions in which case
              you will be charged the Subscription Charge at the end of the
              trial period. If you do not wish to be charged the Subscription
              Charge, you must cancel the trial or promotional subscription
              before the end of the trial or promotional subscription.
              Eligibility for a free trial or promotional subscription is
              determined by Readymaid (Pvt) Ltd at its sole discretion.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              AUTOMATICALLY (AND WITHOUT PRIOR NOTICE TO THE EXTENT ALLOWED AT
              LAW), YOUR SUBSCRIPTION PLAN WILL RENEW AT THE BEGINNING OF EACH
              BILLING CYCLE, AND THE SUBSCRIPTION PRICE WILL BE AUTOMATICALLY
              CHARGED TO A PAYMENT METHOD AVAILABLE IN RPAY . If there are
              insufficient funds or the payment methods in RPay in your User
              Account are declined, Readymaid (Pvt) Ltd will prompt you for a
              new payment method but reserves the right to terminate any unpaid
              Subscription Plan periods without further charges to you. Any paid
              Subscription Charges are non-refundable and non-transferable.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Where VAT (or equivalent taxes) is in force when you buy a
              Subscription Plan, the Subscription Charges shall be inclusive of
              VAT. Where there is a future change in the VAT rate, Readymaid
              (Pvt) Ltd may at its discretion increase/decrease as applicable
              the Subscription Charge. Where VAT (or equivalent taxes) is not in
              force when you buy a Subscription Plan, the Subscription Charges
              shall be exclusive of VAT and if VAT (or equivalent) is later
              introduced, Readymaid (Pvt) Ltd may add the applicable VAT to the
              Subscription Charges.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You can cancel your Subscription Plan at any time prior to your
              next scheduled payment to avoid further charges and renewals. If
              you cancel your Subscription Plan, you will still have access to
              the Benefits until the end of the current Billing Cycle, and will
              not be refunded on a pro-rata basis for any cancellation initiated
              during a Billing Cycle. From time to time, Readymaid (Pvt) Ltd may
              increase the Subscription Charge in which case You will be
              notified before your next Billing Cycle. If you do not wish to be
              charged the new Subscription Charge, you are required to cancel
              your subscription.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd makes no warranties or representations, either
              expressed or implied, and expressly disclaim all liability
              (including consequential damages) with respect to the
              availability, accuracy, timeliness, cost, quality or fitness of
              goods or services provided through the Plus Program. Readymaid
              (Pvt) Ltd will also have no liability if any participating Plus
              Partner improperly denies you the right to avail of a Benefit.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              The Plus Program has no predetermined termination date and may
              continue until such time as Readymaid (Pvt) Ltd decides to
              terminate the Plus Program or a specific Subscription Plan, at any
              time, with or without notice. If the Plus Program is terminated,
              Readymaid (Pvt) Ltd may refund you any unconsumed portion of your
              Subscription Charge without any further obligation or liability,
              including the availability of any Benefits after the conclusion of
              any notice period.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              In addition, Readymaid (Pvt) Ltd reserves the right to modify the
              Plus Program, a Subscription Plan or any Benefit included in it,
              which changes will come into effect immediately, at your next
              Billing Cycle or at any other date determined by Readymaid (Pvt)
              Ltd. Readymaid (Pvt) Ltd may, among other things: (1) change the
              Plus Program terms, including eligibility to or availability of
              Subscription Plans and the rules and terms that govern them; (2)
              increase or decrease the number of Benefits available; and/or (3)
              add or modify rules for redeeming Benefits, including blackout
              dates for use of Benefits.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd reserves the right to cancel your Subscription
              Plan and/or your access to the Plus Program if you appear to be
              using the Subscription Plan in a manner inconsistent with the
              terms or intent of the Plan Program or any portion thereof. This
              includes, but are not limited to: (1) violation of these terms and
              conditions; (2) violation of any national, state or local law or
              regulation in connection with the use of this Plan Program; (3)
              commission of fraud or abuse involving any portion of this Plus
              Program; or (4) action, in any other way, to the detriment of the
              Plus Program or any of its stakeholders; all as may be determined
              by Readymaid (Pvt) Ltd in its sole discretion.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Notwithstanding the foregoing, Plus Program violations, fraud or
              abuse in relation to rewards points and credit, tier status or
              reward usage is subject to appropriate administrative and/or legal
              action by appropriate governmental authorities and/or by Readymaid
              (Pvt) Ltd including, without limitation, the cancellation of the
              account.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Community Support</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              As part of Readymaid (Pvt) Ltd’s support to our communities and
              the Champions operating on our Platform Readymaid (Pvt) Ltd may
              make new features available which allow you to direct Readymaid
              (Pvt) Ltd to support our communities in various ways. These
              features may be changed and updated from time to time; may not be
              available in all areas or markets; and those that are available at
              any given time may be subject to change and/or removal. Readymaid
              (Pvt) Ltd reserves the right to cancel, suspend or modify these
              features at any time without notice, for any reason in its sole
              discretion, and without a liability.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              To offer these features Readymaid (Pvt) Ltd has partnered with
              appropriate entities including Government entities, NGOs and
              charitable organisations (“Participating Organisation”). Please
              note that Readymaid (Pvt) Ltd is not registered as a charitable
              organisation and does not hold itself out as such. Readymaid (Pvt)
              Ltd is not a professional or commercial fundraiser, or a conductor
              or paid collector of fundraising appeals (or similar), or a
              guarantor of donations. The management and allocation of support
              to a Participating Organisation is managed by the Participating
              Organisation as shown on the App. You can ask Readymaid (Pvt) Ltd
              to support the cause of a Participating Organisation either by
              your agreement to burn Reward points pursuant to the Loyalty
              Program or to burn Readymaid (Pvt) Ltd Credits in your balance .
              Upon Readymaid (Pvt) Ltd receiving such agreement from you either
              to burn Readymaid (Pvt) Ltd credits or Rewards points, Readymaid
              (Pvt) Ltd will cancel its liability towards you with respect to
              such Readymaid (Pvt) Ltd credits and/or Rewards points and
              separately on its own behalf support the relevant Participating
              Organisation by transferring an amount equal to the nominal value
              of such Readymaid (Pvt) Ltd credits and/or Rewards points (minus
              any required fees) to the relevant Participating Organisation.
              Readymaid (Pvt) Ltd will make such transfer unless it becomes
              impossible or impracticable to do so. The burning of Readymaid
              (Pvt) Ltd credits and/or Reward points in the manner described
              above is irreversible. Readymaid (Pvt) Ltd makes no warranties or
              representations, either expressed or implied in respect of any of
              the aforementioned methods of support , and expressly disclaims
              all liability (including consequential damages) with respect to
              timeliness, cost, quality or fitness of goods or services provided
              in relation to the same.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              If the Participating Organisation that you designated in your
              listing ceases to be a participating charity or appropriately
              licensed entity before Readymaid (Pvt) Ltd pays the relevant
              Participating Organisation or if payment to that entity becomes
              impracticable, Readymaid (Pvt) Ltd may at its sole discretion
              choose to make payment to a Participating Organisation having a
              similar purpose without notifying you.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Trademarks, service marks or logos that appear in relation to the
              donations feature are the property of their respective owners and
              are likely to be registered trademarks and subject to restrictions
              as to their use. They must not be used without the express
              permission of the trade mark owner.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Communications.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              By becoming a User, you agree to receive communications from us,
              including via e-mail, text message, calls, and push notifications.
              You agree that texts, calls or pre-recorded messages may be
              generated by automatic telephone dialling systems (where allowed
              by and in compliance with local law). Communications from
              Readymaid (Pvt) Ltd, its affiliated companies and/or Champions,
              may include but are not limited to: operational communications
              concerning your User account or use of the Readymaid (Pvt) Ltd
              Platform or Services, updates concerning new and existing features
              on the Readymaid (Pvt) Ltd Platform, communications concerning
              promotions run by us or our third- party partners, and news
              concerning Readymaid (Pvt) Ltd and industry developments. Standard
              text messaging charges applied by your cell phone carrier will
              apply to text messages we send. If you no longer wish to receive
              promotional or marketing-related communications by text message,
              please contact info@Readymaid.com.pk and/or review the Privacy
              Policy for further information.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Charges and Payments</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              As a User, you agree to pay any amounts charged by Readymaid (Pvt)
              Ltd (the “Readymaid (Pvt) Ltd Fee”) (if applicable in your
              jurisdiction) and amounts charged by Champions or other
              independent third party provider for providing Services to you
              (“Charges”).
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              After you have received Services from a Champion, a Merchant
              Partner or other independent third party provider, Readymaid (Pvt)
              Ltd: (i) may facilitate your payment of the applicable Charges on
              behalf of the Champion or the Merchant Partner as such Champion or
              Merchant Partner’s limited payment collection agent and (ii) may
              collect any applicable Readymaid (Pvt) Ltd Fee directly from you
              or, in the case of a cash payment, from the relevant Champion or
              Merchant Partner. Payment of the Charges and Readymaid (Pvt) Ltd
              Fee in the above manner shall in all cases be considered the same
              as payment made directly by you to the Champion, Merchant Partner
              or other independent third party provider or to Readymaid (Pvt)
              Ltd, as applicable. Charges and Readymaid (Pvt) Ltd Fees will be
              inclusive of applicable taxes where required by law, including any
              VAT or sales tax. Charges and Readymaid (Pvt) Ltd Fees paid by you
              are final and non-refundable, unless otherwise determined by
              Readymaid (Pvt) Ltd or the relevant Champion. You retain the right
              to request lower Charges from a Champion for Services received by
              you from such Champion at the time you receive such Services (but
              not after the Service is underway or completed). Readymaid (Pvt)
              Ltd will respond accordingly to any request from a Champion to
              modify the Charges for a particular Service..
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              All Charges and Readymaid (Pvt) Ltd Fees are due immediately and
              payment will be facilitated by Readymaid (Pvt) Ltd using the
              preferred payment method designated in your Account, after which
              Readymaid (Pvt) Ltd, Champion or a Merchant Partner, as
              applicable, will send you a receipt by email (which is not
              equivalent to a tax invoice). If your primary Account payment
              method is determined to be expired, invalid or otherwise not able
              to be charged, you agree that Readymaid (Pvt) Ltd may, on its own
              behalf, and as the Champion or the Merchant Partner’s limited
              payment collection agent, use a secondary payment method in your
              Account, if available.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              As between you and Readymaid (Pvt) Ltd, Readymaid (Pvt) Ltd
              reserves the right to establish, remove and/or revise Readymaid
              (Pvt) Ltd Fees at any time in Readymaid (Pvt) Ltd’s sole
              discretion. Charges may also be varied or revised at any time
              without your consent. Further, you acknowledge and agree that
              Charges and Readymaid (Pvt) Ltd Fees applicable in certain
              geographical areas may increase substantially during times of high
              demand. Readymaid (Pvt) Ltd will use reasonable efforts to inform
              you of Charges and Readymaid (Pvt) Ltd Fees that may apply,
              provided that you will be responsible for Charges and Readymaid
              (Pvt) Ltd Fees incurred under your Account regardless of your
              awareness of such Charges or Readymaid (Pvt) Ltd Fees, as
              applicable, or the amounts thereof.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Repair or Cleaning Fees.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You shall be responsible for the cost of for damage to, or
              necessary cleaning of, Champion property resulting from use of the
              Readymaid (Pvt) Ltd Platform under your Account in excess of
              normal “wear and tear” damages and necessary cleaning (“Repair or
              Cleaning”). In the event that a Champion reports the need for
              Repair or Cleaning, and such Repair or Cleaning request is
              verified by Readymaid (Pvt) Ltd in Readymaid (Pvt) Ltd’s
              reasonable discretion, Readymaid (Pvt) Ltd reserves the right to
              facilitate payment for the reasonable cost of such Repair or
              Cleaning on behalf of the Champion using any of your payment
              methods. Such amounts will be transferred by Readymaid (Pvt) Ltd
              to the applicable Champion and are non-refundable.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Restricted Activities</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              With respect to your use of the Readymaid (Pvt) Ltd Platform and
              your participation in the Services, you agree that you will not:
              (i) impersonate any person or entity (ii) stalk, threaten, or
              otherwise harass any person (including other Users making
              simultaneous use of the Service) iii) violate any law, statute,
              rule, permit, ordinance or regulation (iv) interfere with or
              disrupt the Services or the Readymaid (Pvt) Ltd Platform or the
              servers or networks connected to the Readymaid (Pvt) Ltd Platform
              (v) post information or interact on the Readymaid (Pvt) Ltd
              Platform or with respect to Services in a manner which is false,
              inaccurate, misleading (directly or by omission or failure to
              update information), defamatory, libelous, abusive, obscene,
              profane, offensive, sexually oriented, threatening, harassing, or
              illegal (vi) use the Readymaid (Pvt) Ltd Platform in any way that
              infringes any third party’s rights, including but not limited to:
              intellectual property rights, copyright, patent, trademark, trade
              secret or other proprietary rights or rights of publicity or
              privacy (vii) post, email or otherwise transmit any malicious
              code, files or programs designed to interrupt, damage, destroy or
              limit the functionality of any computer software or hardware or
              telecommunications equipment or surreptitiously intercept or
              expropriate any system, data or personal information (vii) forge
              headers or otherwise manipulate identifiers in order to disguise
              the origin of any information transmitted through the Readymaid
              (Pvt) Ltd Platform (ix) “frame” or “mirror” any part of the
              Readymaid (Pvt) Ltd Platform, without our prior written
              authorization or use meta tags or code or other devices containing
              any reference to us in order to direct any person to any other web
              site for any purpose (x) modify, adapt, translate, reverse
              engineer, decipher, decompile or otherwise disassemble any portion
              of the Readymaid (Pvt) Ltd Platform or any software used on or for
              the Readymaid (Pvt) Ltd Platform (xii) rent, lease, lend, sell,
              redistribute, license or sublicense the Readymaid (Pvt) Ltd
              Platform or access to any portion of the Readymaid (Pvt) Ltd
              Platform (xi) link directly or indirectly to any other web sites
              (xii) transfer or sell your User account, password and/or
              identification to any other party (xiii) discriminate against or
              harass anyone on the basis of race, national origin, religion,
              gender, gender identity, physical or mental disability, medical
              condition, marital status, age or sexual orientation (xiv) cause
              any third party to engage in the restricted activities above. In
              the event that you undertake in any of the above while
              participating in Services, a Champion shall be permitted to refuse
              to provide you Services or, if such Services have commenced, a
              Champion shall be permitted to refuse to continue to provide you
              Services.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Disclaimers; Limitation of Liability; Indemnity</span>
              </strong>
            </li>
          </ul>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>DISCLAIMER.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              THE READYMAID (PVT) LTD PLATFORM AND SERVICES ARE PROVIDED “AS IS”
              AND “AS AVAILABLE.” READYMAID (PVT) LTD DISCLAIMS ALL
              REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, NOT
              EXPRESSLY SET OUT IN THIS AGREEMENT, INCLUDING THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
              AND NON-INFRINGEMENT. IN ADDITION, READYMAID (PVT) LTD MAKES NO
              REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY,
              TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE READYMAID
              (PVT) LTD PLATFORM, SERVICES OR ANY OTHER SERVICES REQUESTED
              THROUGH THE USE OF THE READYMAID (PVT) LTD PLATFORM, OR THAT THE
              READYMAID (PVT) LTD PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE.
              READYMAID (PVT) LTD AND ITS AFFILIATES DO NOT GUARANTEE THE
              QUALITY, SUITABILITY, SAFETY OR ABILITY OF CHAMPIONS. YOU AGREE
              THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE READYMAID
              (PVT) LTD PLATFORM AND SERVICES, AND ANY SERVICE REQUESTED IN
              CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM
              EXTENT PERMITTED UNDER APPLICABLE LAW.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd and its Affiliates are not responsible for the
              conduct, whether online or offline, of any User, Champion, mobile
              operator , or any other third party. You are encouraged to use a
              reasonable degree of sensibility and caution when interacting with
              other Users, Champions or any other third party.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              We are not required to procure insurance for, nor are we
              responsible for private belongings, such as the damage or loss of
              private belongings, including but not limited to damage or loss
              caused by: (i) theft or snatching; (ii) because the belongings are
              left unattended; or (iii) Merchant Partners (or their authorised
              personnel) when providing a service or a product which you have
              requested through the Readymaid (Pvt) Ltd Platform. You are
              advised not to keep your personal belongings unattended, including
              when you request a Merchant Partner to provide services in your
              household or other premises. .
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              By using the Readymaid (Pvt) Ltd Platform and participating in the
              Services, you agree to accept such risks and agree that Readymaid
              (Pvt) Ltd is not responsible for the acts or omissions of Users,
              Champions, Partners, Merchant Partners, mobile operators, or any
              other third party.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You acknowledge that Readymaid (Pvt) Ltd may have its own
              insurance coverage from which you may benefit. In the event
              thereof, you agree to provide proper notification of an insurance
              claim and accept the benefit of any insurance service provided at
              your own risk and you hereby acknowledge that Readymaid (Pvt) Ltd
              is not acting as a broker in connection therewith, nor does it
              provide any guarantees, warranties or any other assurances in
              connection therewith.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Disclaimer - delivery of medical goods:</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Where medicines are delivered to you as part of the Services
              provided through the Readymaid (Pvt) Ltd Platform you hereby
              acknowledge and accept that all products including but not limited
              to any medicinal products made available on the Readymaid (Pvt)
              Ltd App are provided and delivered by third party providers, who
              are not affiliated with Readymaid (Pvt) Ltd and who are
              independently supplying their products and/or services via the
              Readymaid (Pvt) Ltd Platform. You accept the risk associated with
              purchase of such goods and/or services, including but not limited
              to any harmful side effects caused by consumption of any such
              products and/or services.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd cannot confirm the accuracy of the description
              nor quality of the product and/or services supplied by such
              independent third-party service providers. You are requested to
              consult with your medical practitioner before making any such
              purchase and/or consuming any medication and/or availing any
              service. Readymaid (Pvt) Ltd does not guarantee the genuineness of
              any medical claims including but not limited to date of expiry as
              claimed by the third-party goods provider distributing any
              medication via the Readymaid (Pvt) Ltd Platform. All products made
              available via the Readymaid (Pvt) Ltd Platform are delivered by
              independent third-party goods service providers, Readymaid (Pvt)
              Ltd is neither the manufacturer nor the distributor of any goods
              delivered via the Readymaid (Pvt) Ltd Platform.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Readymaid (Pvt) Ltd disclaims responsibility for any harm to
              persons resulting from any instructions or product descriptions
              referred to in the Readymaid (Pvt) Ltd App. Readymaid (Pvt) Ltd is
              not associated with any manufacturer of medicines or other
              products on the Readymaid (Pvt) Ltd Platform. We do not warrant
              that the products being supplied will meet your requirements.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              It is imperative to seek professional advice from your physician
              before purchasing or consuming any medicine and/or service in
              order to be completely aware of the indications, side effects,
              drug interactions, effects of missed dose or overdose of the
              medicines you may order using the Readymaid (Pvt) Ltd Platform.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>LIMITATION OF LIABILITY.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              READYMAID (PVT) LTD AND ITS AFFILIATES AND ITS PARTNERS SHALL NOT
              BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE
              OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA,
              PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH,
              OR OTHERWISE RESULTING FROM ANY USE OF THE READYMAID (PVT) LTD
              PLATFORM OR SERVICES, EVEN IF READYMAID (PVT) LTD HAS BEEN ADVISED
              OF THE POSSIBILITY OF SUCH DAMAGES. READYMAID (PVT) LTD AND ITS
              AFFILIATES AND ITS PARTNERS SHALL NOT BE LIABLE FOR ANY DAMAGES,
              LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON
              THE READYMAID (PVT) LTD PLATFORM OR THE SERVICES OR YOUR INABILITY
              TO ACCESS OR USE THE READYMAID (PVT) LTD PLATFORM OR THE SERVICES;
              OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY
              CHAMPION OR OTHER THIRD PARTY PROVIDER, EVEN IF READYMAID (PVT)
              LTD OR ITS AFFILIATES OR ITS PARTNERS HAVE BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. READYMAID (PVT) LTD AND ITS
              AFFILIATES AND ITS PARTNERS SHALL NOT BE LIABLE FOR DELAY OR
              FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND OUR REASONABLE
              CONTROL. YOU ACKNOWLEDGE THAT CHAMPIONS PROVIDING SERVICES
              REQUESTED THROUGH SOME REQUEST BRANDS MAY OFFER PEER-TO-PEER
              SERVICES AND MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED. IN
              NO EVENT SHALL READYMAID (PVT) LTD’S AND/OR AN AFFILIATE’S AND/OR
              A PARTNER’S TOTAL LIABILITY TO YOU IN CONNECTION WITH THE
              READYMAID (PVT) LTD PLATFORM, THE SERVICES, ANY WEBSITE OR
              APPLICATION LINKED THERETO, ANY MATERIAL POSTED ON THE FOREGOING
              OR ACTS OR OMISSIONS OF PARTNERS OR OTHER PARTNERS FOR ALL
              DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED TWO THOUSAND UNITED
              STATES DOLLARS OR EQUIVALENT AMOUNT IN ANOTHER CURRENCY.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              THE READYMAID (PVT) LTD PLATFORM MAY BE USED BY YOU TO REQUEST AND
              SCHEDULE THE SERVICES WITH CHAMPIONS BUT YOU AGREE THAT READYMAID
              (PVT) LTD, ITS AFFILIATES, PARTNERS AND OTHER PARTNERS HAVE NO
              RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY SERVICE PROVIDED
              TO YOU BY CHAMPIONS OTHER THAN AS EXPRESSLY SET FORTH IN THIS
              AGREEMENT. THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 6 DO NOT
              PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT
              CANNOT BE EXCLUDED UNDER APPLICABLE LAW.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Indemnity.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You agree to indemnify and hold Readymaid (Pvt) Ltd, its
              Affiliates, Partners, Merchant Partners and other partners and
              their officers, directors, employees and agents harmless from any
              and all claims, demands, losses, liabilities, and expenses
              (including attorneys’ fees) arising out of or in connection with:
              (i) your use of the Readymaid (Pvt) Ltd Platform and the Services
              or services or goods obtained through your use of the Readymaid
              (Pvt) Ltd Platform; (ii) your breach or violation of this
              Agreement or(iii) your violation of the rights of any third party,
              including Champions.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>
                  General Rules on Competitions, Contests and Giveaways
                </span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Unless otherwise specified, Readymaid (Pvt) Ltd will conduct all
              competitions, contests and giveaways (each a “Competition”) as
              described in this section and by entering or participating in a
              Competition, each entrant or participant (“Entrant”) agrees to
              these rules whether or not any separate additional specific terms
              apply to the Competition.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              When entering or participating in a Competition, an Entrant may be
              eligible for a prize specified in the corresponding promotional
              material (the “Prize”). Readymaid (Pvt) Ltd may reject an entry at
              its own discretion, provided that such rejection will not conflict
              with applicable law.{" "}
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              The Prize is non-transferable, non-exchangeable and no cash
              alternative is offered. In the event of a Prize being unavailable,
              Readymaid (Pvt) Ltd reserves the right to offer an alternative
              prize of equal or greater value if circumstances beyond Readymaid
              (Pvt) Ltd’s control makes it necessary to do so. To the extent
              permitted by law, the Competition Winner will be responsible for
              any and all taxes incurred in relation to receipt of the Prize.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Participation in a Competition does not automatically mean that
              the Entrant has won the Prize. A winning entry (the “Competition
              Winner”) will be chosen by random draw (performed by a computer
              process or supervised by an independent third party) or by a panel
              of individuals adjudicating the entries. Reasonable efforts will
              made to contact the Competition Winner but if the Competition
              Winner cannot be contacted or fails to reply to Readymaid (Pvt)
              Ltd’s communication within the communicated timeframe, Readymaid
              (Pvt) Ltd reserves the right to offer the prize to the next
              eligible Entrant. Readymaid (Pvt) Ltd will not be liable if the
              Prize does not reach the Competition Winner for reasons beyond
              Readymaid (Pvt) Ltd’s reasonable control.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Without prejudice to the “Disclaimers; Limitation of Liability;
              Indemnity” section, neither Readymaid (Pvt) Ltd nor any Affiliates
              or Partners involved in a Competition will accept responsibility
              or liability for any errors, omissions, interruptions, deletion,
              or unauthorized access to Competition entries, or entries lost or
              delayed whether or not arising as a result of server functions,
              virus, bugs, or other causes outside Readymaid (Pvt) Ltd’s, an
              Affiliate’s and/or a Partner’s control. IN NO EVENT SHALL
              READYMAID (PVT) LTD’S AND/OR AN AFFILIATE’S AND/OR A PARTNER’S
              TOTAL LIABILITY TO ANY ENTRANT FOR ALL DAMAGES, LOSSES AND CAUSES
              OF ACTION IN CONNECTION WITH ANY COMPETITION OR ANY MATERIAL
              PUBLISHED IN RELATION TO A COMPETITION EXCEED THE MONETARY VALUE
              OF THE PRIZE. Readymaid (Pvt) Ltd reserves the right to cancel or
              amend the Competition and any associated terms and conditions. Any
              changes will be notified to the Entrants as soon as reasonably
              practical. If these terms do not, or do not clearly, cater for a
              solution to any matter which may arise, then Readymaid (Pvt) Ltd
              shall have the sole discretion to decide finally on any such
              matter. Readymaid (Pvt) Ltd’s decisions on any aspect of the
              Competition is final and binding and no correspondence will be
              entered into about it.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Unless otherwise specified, Readymaid (Pvt) Ltd will conduct all
              competitions, contests and giveaways (each a “Competition”) as
              described in this section and by entering or participating in a
              Competition, each entrant or participant (“Entrant”) agrees to
              these rules whether or not any separate additional specific terms
              apply to the Competition.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Dispute Resolution</span>
              </strong>
            </li>
          </ul>{" "}
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Arbitration.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You agree that any dispute, claim or controversy arising out of or
              relating to this Agreement or the breach, termination,
              enforcement, interpretation or validity thereof or the use of the
              Readymaid (Pvt) Ltd Platform or Services (collectively,
              "Disputes") will be settled by binding arbitration between you and
              any relevant Affiliate in your jurisdiction, or between you and
              Readymaid (Pvt) Ltd Networks if no other Affiliate is incorporated
              in your jurisdiction, except that each party retains the right to
              seek injunctive or other equitable relief in a court of competent
              jurisdiction to prevent the actual or threatened infringement,
              misappropriation or violation of a party's copyrights, trademarks,
              trade secrets, patents or other intellectual property rights. You
              acknowledge and agree that you and Readymaid (Pvt) Ltd are each
              waiving the right to a trial by jury or to participate as a
              plaintiff or class in any purported class action or representative
              proceeding. Further, unless both you and the relevant Affiliate
              otherwise agree in writing, the arbitrator may not consolidate
              more than one person's claims, and may not otherwise preside over
              any form of any class or representative proceeding. If this
              specific paragraph is held unenforceable, then the entirety of
              this "Dispute Resolution" section will be deemed void. Except as
              provided in the preceding sentence, this "Dispute Resolution"
              section will survive any termination of this Agreement. This
              “Dispute Resolution” section applies to all Disputes (as defined
              below) between you and Readymaid (Pvt) Ltd and/or any of its
              Affiliates.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Arbitration Process and Rules.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Any dispute, conflict, claim or controversy arising out of or broadly in connection with or relating to the Readymaid (Pvt) Ltd Platform or this Agreement, including those relating to its validity, its construction or its enforceability (any “Dispute”) shall be first mandatorily submitted to mediation proceedings under the International Chamber of Commerce Mediation Rules (“ICC Mediation Rules”). If such Dispute has not been settled within sixty (60) days after a request for mediation has been submitted under such ICC Mediation Rules, such Dispute can be referred to and shall be exclusively and finally resolved by arbitration under the Rules of Arbitration of the International Chamber of Commerce (“ICC Arbitration Rules”). The ICC Rules' Emergency Arbitrator provisions are excluded. The Dispute shall be resolved by one (1) arbitrator to be appointed in accordance with the ICC Rules. The place of both mediation and arbitration shall be in the city in which the applicable Affiliate with which you have a Dispute has its registered office. The language of the mediation and/or arbitration shall be English, unless you do not speak English, in which case the mediation and/or arbitration shall be conducted in both English and your native language. The existence and content of the mediation and arbitration proceedings, including documents and briefs submitted by the parties, correspondence from and to the International Chamber of Commerce, correspondence from the mediator, and correspondence, orders and awards issued by the sole arbitrator, shall remain strictly confidential and shall not be disclosed to any third party without the express written consent from the other party unless: (i) the disclosure to the third party is reasonably required in the context of conducting the mediation or arbitration proceedings; and (ii) the third party agrees unconditionally in writing to be bound by the confidentiality obligation stipulated herein.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Other Provisions</span>
              </strong>
            </li>
          </ul>
           <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Choice of Law.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              This Agreement is governed by and construed in accordance with the laws of the jurisdiction in which the relevant Affiliate is incorporated, without giving effect to any conflict of law principles, except as may be otherwise provided in supplemental terms applicable to your region.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Claims of Copyright Infringement.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              Claims of copyright infringement should be sent to Readymaid (Pvt) Ltd at info@Readymaid.com.pk.
            </span>
          </p>
          <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>Notice.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
             Readymaid (Pvt) Ltd may give notice by means of a general notice on the Readymaid (Pvt) Ltd Platform, electronic mail to your email address in your Account, or by written communication sent to your address as set forth in your Account. You may give notice to Readymaid (Pvt) Ltd by written communication to Readymaid (Pvt) Ltd's email address at info@Readymaid.com.pk.
            </span>
          </p>
           <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
            <li style={{ listStyle: "none", fontSize: "16px" }}>
              <strong>
                <span>General.</span>
              </strong>
            </li>
          </ul>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              You may not assign or transfer your rights under this Agreement in whole or in part without Readymaid (Pvt) Ltd’s prior written approval. You give your approval to Readymaid (Pvt) Ltd for it to assign or transfer its rights and obligations under this Agreement in whole or in part, including to: (i) a subsidiary or affiliate; (ii) an acquirer of Readymaid (Pvt) Ltd’s equity, business or assets; or (iii) a successor by merger. No joint venture, partnership, employment or agency relationship exists between you, Readymaid (Pvt) Ltd or any Champion as a result of the contract between you and Readymaid (Pvt) Ltd or use of the Readymaid (Pvt) Ltd Platform.
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "5.0pt",
              marginLeft: ".5in",
              lineHeight: "normal",
              fontSize: "15px",

              textAlign: "justify",
            }}
          >
            <span
              style={{
                color: "black",
                background: "white",
              }}
            >
              If any provision of this Agreement is held to be illegal, invalid or unenforceable, in whole or in part, under any law, such provision or part thereof shall to that extent be deemed not to form part of this Agreement but the legality, validity and enforceability of the other provisions in this Agreement shall not be affected. In that event, the parties shall replace the illegal, invalid or unenforceable provision or part thereof with a provision or part thereof that is legal, valid and enforceable and that has, to the greatest extent possible, a similar effect as the illegal, invalid or unenforceable provision or part thereof, given the contents and purpose of this Agreement. This Agreement constitutes the entire agreement and understanding of the parties with respect to its subject matter and replaces and supersedes all prior or contemporaneous agreements or undertakings regarding such subject matter.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight } from "react-feather";

export function ProductsBuisnessPackageEntry({ title, info, price, style }) {
  return (
    <div className="products__buisness__packages__entry" style={style}>
      <div
        className="products__buisness__packages__entry__heading"
        style={style ? { color: "black" } : null}
      >
        {title}
      </div>
      <div
        className="products__buisness__packages__entry__info"
        style={style ? { color: "black" } : null}
        dangerouslySetInnerHTML={{ __html: info }}
      ></div>
      <div
        className="products__buisness__packages__entry__price"
        style={style ? { color: "black" } : null}
      >
        {price}/month
      </div>
      <Link
        to="/"
        className="products__buisness__packages__entry__link"
        style={style ? { color: "black" } : null}
      >
        Go for this <ChevronRight size={20} color="currentColor" />
      </Link>
    </div>
  );
}

export const faqData = [
  {
    heading: "How do I create an Account?",
    info: "<ul><li>To create an account, you need a valid email address and mobile number. Download the app for your iOS or Android device and sign up with your mobile number or email. </li><li>We’ll send you a verification code via Email or SMS which you need to enter to sign up. If the code does not arrive, you can request to resend it</li><li>Once your account is verified, you’re ready to use ReadyMaid!</li><li>Alternatively, if you're comfortable with linking your Facebook profile to your account, you can sign up via Facebook. The app will redirect you to a Facebook page where the email and password are required to authorize usage.</li><li>You can also create an account on the web at www.ReadyMaid.pk.</li></ul>",
  },
  {
    heading: "How can I hire a resource?",
    info: "<ul><li>Hiring resource is simple. Once, your account is created, you can simply click on Hire a Resource tab. In the menu bar, click the service you need and submit the form. Our team will contact you to complete the formalities and allocate you right resource accordingly. You can also hire a resource by dialing our helpline number and our team will complete the process for you. </li></ul>",
  },
  {
    heading: "How do I become a resource?",
    info: "<ul><li>Becoming a resource is a simple three step process. First you will need to register with us by completing the form available on the website & App. After reviewing your application, we will invite you for an interview session and train you. Once the training is complete, you can start working with us.</li></ul>",
  },
  {
    heading: "How do I pay?",
    info: "<ul><li>Payment is made simple with ReadyMaid. If you hire a long-term service, then monthly payment will be made vide bank transfer, credit card and or debit card. If short term service is availed than a partial payment will be made before the start of service and remaining payment after the completion of service. The mode of payment can be online bank transfer, credit, or debit card.</li></ul>",
  },
  {
    heading: "Where can I get more information & support?",
    info: "<ul><li>We do our best to provide you with all the help you may need in our FAQs. If you need to get in touch with us, you can go to the app Menu > Get help.</li></ul>",
  },
];

import React from "react";
import Select from "react-select";

export function ProductsBecomeResourceInput({
  errorMessageVisibility,
  errorMessage,
  value,
  onChange,
  onKeyDownPress,
  type,
  options,
  svg,
  ...props
}) {
  debugger;
  const styles = {
    singleValue: (base) => ({
      ...base,
      // color: "#fff",
      border: "none",
      outline: "none",
    }),
    input: (base) => ({
      ...base,
      // color: "#fff",
      border: "none",
      outline: "none",
    }),
  };
  return (
    <div style={{ position: "relative", width: "49%" }}>
      {type == "select" ? (
        <>
          <div className="products__become__resource__form__content__input">
            {svg}
            <Select
              {...props}
              value={value}
              onChange={onChange}
              styles={styles}
              className="products__become__resource__form__content__input__field"
              options={options}
            />
          </div>
          <div className="error__message">
            {errorMessageVisibility ? errorMessage : null}
          </div>
        </>
      ) : (
        <>
          <div className="products__become__resource__form__content__input">
            {svg}
            <input
              {...props}
              value={value}
              onChange={onChange}
              type={type}
              className="products__become__resource__form__content__input__field"
            />
          </div>
          <div className="error__message">
            {errorMessageVisibility ? errorMessage : null}
          </div>
        </>
      )}
    </div>
  );
}

import React from "react";
import { aboutjumbotron } from "../assets";
import { Link } from "react-router-dom";

export function AboutJumbotronSection() {
  return (
    <div className="about__jumbotron">
      <img
        src={aboutjumbotron}
        alt="aboutjumbotron"
        className="about__jumbotron__img"
      />
      <div className="about__jumbotron__overlay">
        <div className="about__jumbotron__overlay__heading">About Us</div>
        <div className="about__jumbotron__overlay__info">
          At ReadyMaids, we are your unrivaled one-stop destination and dynamic
          marketplace, dedicated to delivering top-tier skilled labor services
          for both individual consumers and corporations. Our comprehensive
          array of offerings includes professionals such as Maids, Gardeners,
          Guards, Chefs, Chauffeurs, Nurses, Nannies, Janitors, Plumbers,
          Electricians, and more, ensuring a sophisticated and all-encompassing
          solution for your workforce requirements.
        </div>
        <div className="about__jumbotron__overlay__buttons">
          {/* <Link
            to="/"
            className="about__jumbotron__overlay__buttons__link about__jumbotron__overlay__buttons__link__primary"
          >
            More
          </Link> */}
          <Link
            to="/products"
            className="about__jumbotron__overlay__buttons__link about__jumbotron__overlay__buttons__link__secondary"
          >
            Services
          </Link>
        </div>
      </div>
    </div>
  );
}

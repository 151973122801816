import React from "react";

export function AuthInput({
  errorMessageVisibility,
  errorMessage,
  value,
  onChange,
  onKeyDownPress,
  icon,
  type,
  ...props
}) {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div className="auth__input">
        <div className="auth__input__icon">{icon}</div>
        <input
          className="auth__input__field"
          {...props}
          value={value}
          onChange={onChange}
          type={type}
        />
      </div>
      <div className="error__message">
        {errorMessageVisibility ? errorMessage : null}
      </div>
    </div>
  );
}

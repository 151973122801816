import React, { useState } from "react";
import { useEffect } from "react";
import { Zoom } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { getServices } from "../APIs/Repos";
import { ERROR_CODES } from "../Constants";
import { homeSolutionsData } from "../constants/homeSolutionsData";

export function HomeSolutionsSection() {
  const navigate = useNavigate();
  const [servicesList, setServicesList] = useState([]);
  const [serviceType, setServiceType] = useState(1);

  useEffect(() => {
    getServicesAPICall();
  }, []);

  const getServicesAPICall = () => {
    getServices(serviceType)
      .then((res) => {
        if (res.data.error_code == ERROR_CODES.SUCCESS) {
          setServicesList(res.data.result);
        } else {
        }
      })
      .catch((error) => {
        console.log("Services ERROR", error);
      });
  };
  return (
    <div className="home__solutions">
      <div className="home__solutions__heading">
        One Solution For All Your Needs
      </div>
      <div className="home__solutions__content">
        {homeSolutionsData.map((solution) => (
          // <Zoom>
          <div
            onClick={() => navigate("/signup")}
            key={JSON.stringify(solution)}
            className="home__solutions__content__entry"
          >
            <div className="home__solutions__content__entry__icon">
              {solution.icon}
            </div>
            <div className="home__solutions__content__entry__text">
              {solution.label}
            </div>
          </div>
          // </Zoom>
        ))}
      </div>
    </div>
  );
}

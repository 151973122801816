import React from "react";
import { appstore, googleplay, homejumbotron, QRCode } from "../assets";
import { Fade, Reveal, Zoom } from "react-reveal";

export function HomeJumbotronSection() {
  return (
    <div className="home__jumbotron">
      <img
        src={homejumbotron}
        alt="homejumbotron"
        className="home__jumbotron__img"
      />
      <div className="home__jumbotron__overlay">
        <div className="home__jumbotron__overlay__content">
          <div className="home__jumbotron__overlay__content__heading">
            <Fade>Maid Access, Made Easy</Fade>
          </div>
          <div className="home__jumbotron__overlay__content__info">
            <Fade>
              Revolutionizing workforce solutions: A distinctive online
              marketplace catering to both individual consumers and
              corporations, connecting them with skilled professionals for a
              wide array of services.
            </Fade>
          </div>
          <div className="home__jumbotron__overlay__content__buttons">
            <a
              href="https://www.apple.com/iphone/"
              className="home__jumbotron__overlay__content__buttons__link"
              target="_blank"
            >
              <Zoom>
                <img
                  src={appstore}
                  alt="appstore"
                  className="home__jumbotron__overlay__content__buttons__link__img"
                />
              </Zoom>
            </a>
            <a
              href="https://play.google.com/store/games?hl=en&gl=US"
              target="_blank"
              className="home__jumbotron__overlay__content__buttons__link"
            >
              <Zoom>
                <img
                  src={googleplay}
                  alt="googleplay"
                  className="home__jumbotron__overlay__content__buttons__link__img"
                />
              </Zoom>
            </a>
          </div>
          <div className="home__jumbotron__overlay__content__QR__container">
            {/* <div className="home__jumbotron__overlay__content__QR__container__box">
              <img
                src={QRCode}
                className="home__jumbotron__overlay__content__QR"
              />
              <div className="home__jumbotron__overlay__content__QR__container__info">
                <span> Scan Me</span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { ProductsBecomeResourceInput } from "../components/ProductsBecomeResourceInput";
import { isNullOrEmpty, stringsNotEqual } from "../utils/TextUtils";
import { ERROR_CODES, VALIDATION_REGEX } from "../Constants";
import toast, { Toaster } from "react-hot-toast";
import { Plus, X } from "react-feather";
import { resourceSignUp, services } from "../APIs/Repos";

export function ProductsBecomeResource() {
  //#region Variables
  let [isChecked, setIschecked] = useState(false);
  const [isCheckedErrorMessage, setIscheckedErrorMessage] = useState("");
  const [isCheckedErrorMessageVisibility, setIscheckedErrorMessageVisibility] =
    useState(false);

  let [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [nameErrorMessageVisibility, setNameErrorMessageVisibility] =
    useState(false);

  let [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailErrorMessageVisibility, setEmailErrorMessageVisibility] =
    useState(false);

  let [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");
  const [
    phoneNumberErrorMessageVisibility,
    setPhoneNumberErrorMessageVisibility,
  ] = useState(false);

  let [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordErrorMessageVisibility, setPasswordErrorMessageVisibility] =
    useState(false);

  let [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  const [
    confirmPasswordErrorMessageVisibility,
    setConfirmPasswordErrorMessageVisibility,
  ] = useState(false);

  let [profession, setprofession] = useState("");
  const [professionErrorMessage, setprofessionErrorMessage] = useState("");
  const [
    professionErrorMessageVisibility,
    setprofessionErrorMessageVisibility,
  ] = useState(false);

  let [yearsOfExperience, setyearsOfExperience] = useState("");
  const [yearsOfExperienceErrorMessage, setyearsOfExperienceErrorMessage] =
    useState("");
  const [
    yearsOfExperienceErrorMessageVisibility,
    setyearsOfExperienceErrorMessageVisibility,
  ] = useState(false);

  let [cnic, setcnic] = useState("");
  const [cnicErrorMessage, setcnicErrorMessage] = useState("");
  const [cnicErrorMessageVisibility, setcnicErrorMessageVisibility] =
    useState(false);

  let [expertCertificate, setexpertCertificate] = useState("");
  const [expertCertificateErrorMessage, setexpertCertificateErrorMessage] =
    useState("");
  const [
    expertCertificateErrorMessageVisibility,
    setexpertCertificateErrorMessageVisibility,
  ] = useState(false);

  let [policeCertificate, setpoliceCertificate] = useState("");
  const [policeCertificateErrorMessage, setpoliceCertificateErrorMessage] =
    useState("");
  const [
    policeCertificateErrorMessageVisibility,
    setpoliceCertificateErrorMessageVisibility,
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  let [servicesList, setServicesList] = useState([]);
  const [servicesOptions, setServicesOptions] = useState([]);

  //#endregion

  //#region Name Validation
  const onNameTextChangeListener = (event) => {
    setName((name = event.currentTarget.value));
    setNameErrorMessageAndVisibilityAndIsValid(name);
  };
  const setNameErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setNameErrorMessageAndVisibility(true, "Enter name");
      return isViewValid;
    } else {
      setNameErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setNameErrorMessageAndVisibility = (visibility, text) => {
    setNameErrorMessageVisibility(visibility);
    setNameErrorMessage(text);
  };

  //#endregion

  //#region Email Validation
  const onEmailTextChangeListener = (event) => {
    setEmail((email = event.currentTarget.value));
    setEmailErrorMessageAndVisibilityAndIsValid(email);
  };
  const setEmailErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setEmailErrorMessageAndVisibility(true, "Enter email");
      return isViewValid;
    } else if (!VALIDATION_REGEX.EMAIL_REGEX.test(enteredText)) {
      setEmailErrorMessageAndVisibility(true, "Enter valid email");
    } else {
      setEmailErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setEmailErrorMessageAndVisibility = (visibility, text) => {
    setEmailErrorMessageVisibility(visibility);
    setEmailErrorMessage(text);
  };

  //#endregion

  //#region PhoneNumber Validation
  const onPhoneNumberTextChangeListener = (event) => {
    if (isNaN(event.currentTarget.value)) {
      {
        event.currentTarget.value = null;
        setPhoneNumberErrorMessageVisibility(true);
        setPhoneNumberErrorMessage("Digits only");
      }
    } else {
      setPhoneNumber((phoneNumber = event.currentTarget.value));
      setPhoneNumberErrorMessageAndVisibilityAndIsValid(phoneNumber);
    }
  };
  const setPhoneNumberErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setPhoneNumberErrorMessageAndVisibility(true, "Enter phone number");
      return isViewValid;
    } else {
      setPhoneNumberErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setPhoneNumberErrorMessageAndVisibility = (visibility, text) => {
    setPhoneNumberErrorMessageVisibility(visibility);
    setPhoneNumberErrorMessage(text);
  };

  //#endregion

  //#region Password Validation
  const onPasswordTextChangeListener = (event) => {
    setPassword((password = event.currentTarget.value));
    setPasswordErrorMessageAndVisibilityAndIsValid(password);
  };
  const setPasswordErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    debugger;
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setPasswordErrorMessageAndVisibility(true, "Enter password");
    } else if (enteredText.length < 8) {
      setPasswordErrorMessageAndVisibility(true, "Min 8 characters");
    } else {
      setPasswordErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const setPasswordErrorMessageAndVisibility = (visibility, text) => {
    setPasswordErrorMessageVisibility(visibility);
    setPasswordErrorMessage(text);
  };

  //#endregion

  //#region ConfirmPassword Validation
  const onConfirmPasswordTextChangeListener = (event) => {
    setConfirmPassword((confirmPassword = event.currentTarget.value));
    setConfirmPasswordErrorMessageAndVisibilityAndIsValid(confirmPassword);
  };
  const setConfirmPasswordErrorMessageAndVisibilityAndIsValid = (
    enteredText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setConfirmPasswordErrorMessageAndVisibility(
        true,
        "Enter confirm password"
      );
    } else if (stringsNotEqual(password, confirmPassword)) {
      setConfirmPasswordErrorMessageAndVisibility(
        true,
        "Password doesn't match"
      );
    } else {
      setConfirmPasswordErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const setConfirmPasswordErrorMessageAndVisibility = (visibility, text) => {
    setConfirmPasswordErrorMessageVisibility(visibility);
    setConfirmPasswordErrorMessage(text);
  };

  //#endregion

  //#region IsChecked Validation
  const onIsCheckedTextChangeListener = (value) => {
    setIschecked((isChecked = value));
    setIsCheckedErrorMessageAndVisibilityAndIsValid(isChecked);
  };
  const setIsCheckedErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (enteredText == false) {
      setIsCheckedErrorMessageAndVisibility(
        true,
        "Agree to our terms and conditions"
      );
    } else {
      setIsCheckedErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const setIsCheckedErrorMessageAndVisibility = (visibility, text) => {
    setIscheckedErrorMessageVisibility(visibility);
    setIscheckedErrorMessage(text);
  };

  //#endregion

  //#region cnic Validation
  const oncnicTextChangeListener = (event) => {
    if (isNaN(event.currentTarget.value)) {
      {
        event.currentTarget.value = null;
        setcnicErrorMessageVisibility(true);
        setcnicErrorMessage("Digits only");
      }
    } else {
      setcnic((cnic = event.currentTarget.value));
      setcnicErrorMessageAndVisibilityAndIsValid(cnic);
    }
  };
  const setcnicErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setcnicErrorMessageAndVisibility(true, "Enter cnic");
      return isViewValid;
    } else if (enteredText.length != 13) {
      setcnicErrorMessageAndVisibility(true, "Enter valid cnic");
    } else {
      setcnicErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setcnicErrorMessageAndVisibility = (visibility, text) => {
    setcnicErrorMessageVisibility(visibility);
    setcnicErrorMessage(text);
  };

  //#endregion

  //#region profession Validation
  const onprofessionTextChangeListener = (event) => {
    setprofession((profession = event));
    setprofessionErrorMessageAndVisibilityAndIsValid(profession);
  };
  const setprofessionErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setprofessionErrorMessageAndVisibility(true, "Enter profession");
      return isViewValid;
    } else {
      setprofessionErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setprofessionErrorMessageAndVisibility = (visibility, text) => {
    setprofessionErrorMessageVisibility(visibility);
    setprofessionErrorMessage(text);
  };

  //#endregion

  //#region yearsOfExperience Validation
  const onyearsOfExperienceTextChangeListener = (event) => {
    setyearsOfExperience((yearsOfExperience = event.currentTarget.value));
    setyearsOfExperienceErrorMessageAndVisibilityAndIsValid(yearsOfExperience);
  };
  const setyearsOfExperienceErrorMessageAndVisibilityAndIsValid = (
    enteredText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setyearsOfExperienceErrorMessageAndVisibility(true, "Enter experience");
      return isViewValid;
    } else {
      setyearsOfExperienceErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setyearsOfExperienceErrorMessageAndVisibility = (visibility, text) => {
    setyearsOfExperienceErrorMessageVisibility(visibility);
    setyearsOfExperienceErrorMessage(text);
  };

  //#endregion

  //#region View Valid
  const isViewValid = () => {
    var viewValid = setNameErrorMessageAndVisibilityAndIsValid(name);
    if (viewValid)
      viewValid = setprofessionErrorMessageAndVisibilityAndIsValid(profession);
    if (viewValid)
      viewValid = setEmailErrorMessageAndVisibilityAndIsValid(email);
    if (viewValid)
      viewValid =
        setyearsOfExperienceErrorMessageAndVisibilityAndIsValid(
          yearsOfExperience
        );
    if (viewValid)
      viewValid =
        setPhoneNumberErrorMessageAndVisibilityAndIsValid(phoneNumber);
    if (viewValid) viewValid = setcnicErrorMessageAndVisibilityAndIsValid(cnic);
    if (viewValid)
      viewValid = setPasswordErrorMessageAndVisibilityAndIsValid(password);
    if (viewValid)
      viewValid =
        setConfirmPasswordErrorMessageAndVisibilityAndIsValid(confirmPassword);
    if (viewValid)
      viewValid = setIsCheckedErrorMessageAndVisibilityAndIsValid(isChecked);

    return viewValid;
  };
  //#endregion

  //#region API Calls

  const resourceSignUpApiCall = () => {
    let professionObject = null;
    if (profession) {
      professionObject = {
        id: profession.value,
        name: profession.label,
        extra_charges: profession.extraCharges,
        image_path: profession.image,
        is_active: profession.isActive,
        description: profession.description,
        rate: profession.rate,
        service_type: profession.serviceType,
        user_id: profession.userId,
      };
    }
    let data = {
      email: email,
      name: name,
      contact_number: phoneNumber,
      cnic: cnic,
      password: password,
      years_of_experience: yearsOfExperience,
      profession: professionObject,
    };
    var formData = new FormData();
    formData.append("model", JSON.stringify(data));
    formData.append("police_certificate", policeCertificate);
    formData.append("experience_certificate", expertCertificate);
    if (isViewValid()) {
      setIsLoading(true);
      resourceSignUp(formData)
        .then((res) => {
          setIsLoading(false);
          if (res.data.error_code == ERROR_CODES.SUCCESS) {
            setName("");
            setEmail("");
            setprofession("");
            setPhoneNumber("");
            setPassword("");
            setConfirmPassword("");
            setcnic("");
            setyearsOfExperience("");
            toast(
              "Thank you for sharing your information, please signup on our App to benefit from our services"
            );
            window.location.reload();
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert("Ops! unable to sign up, please try later");
        });
    }
  };

  const servicesApiCall = () => {
    let newArray = [];
    services()
      .then((res) => {
        console.log("response", res);
        if (res.data.error_code == ERROR_CODES.SUCCESS) {
          setServicesList((servicesList = res.data.result));
          for (let index = 0; index < servicesList.length; index++) {
            const element = servicesList[index];
            let object = {
              value: element.id,
              label: element.name,
              extraCharges: element.extra_charges,
              image: element.image_path,
              isActive: element.is_active,
              description: element.description,
              rate: element.rate,
              serviceType: element.service_type,
              userId: element.user_id,
            };
            newArray.push(object);
          }
          setServicesOptions(newArray);
        }
      })
      .catch((error) => {
        console.log("Services get list ERROR", error);
      });
  };

  //#endregion

  useEffect(() => {
    window.scrollTo(0, 0);
    servicesApiCall();
  }, []);

  return (
    <div className="products__become__resource" id="resource-signup">
      <div className="products__become__resource__form">
        <div className="products__become__resource__form__heading">
          To become a resource
          <span>Create an account</span>
        </div>
        <div className="products__become__resource__form__content">
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.122"
                height="20.118"
                viewBox="0 0 20.122 20.118"
              >
                <path
                  id="Icon_ionic-ios-person"
                  data-name="Icon ionic-ios-person"
                  d="M23.608,23.135c-.358-1.583-2.4-2.355-3.112-2.6a23.2,23.2,0,0,0-2.594-.5,2.635,2.635,0,0,1-1.2-.553,10.486,10.486,0,0,1-.08-2.454,7.384,7.384,0,0,0,.568-1.08,13.935,13.935,0,0,0,.418-1.887s.408,0,.553-.717a7.054,7.054,0,0,0,.368-1.668c-.03-.573-.344-.558-.344-.558a8.729,8.729,0,0,0,.339-2.554C18.57,6.516,16.967,4.5,14.064,4.5,11.122,4.5,9.554,6.516,9.6,8.563a9.1,9.1,0,0,0,.334,2.554s-.314-.015-.344.558a7.054,7.054,0,0,0,.368,1.668c.139.717.553.717.553.717a13.935,13.935,0,0,0,.418,1.887,7.385,7.385,0,0,0,.568,1.08,10.486,10.486,0,0,1-.08,2.454,2.635,2.635,0,0,1-1.2.553,23.2,23.2,0,0,0-2.594.5c-.707.249-2.753,1.021-3.112,2.6a.4.4,0,0,0,.393.483H23.22A.4.4,0,0,0,23.608,23.135Z"
                  transform="translate(-3.999 -4)"
                  fill="none"
                  stroke="#0d0d0d"
                  strokeWidth="1"
                />
              </svg>
            }
            type="text"
            placeholder="Company Name"
            value={name}
            onChange={(event) => onNameTextChangeListener(event)}
            errorMessage={nameErrorMessage}
            errorMessageVisibility={nameErrorMessageVisibility}
          />
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.6"
                height="13.92"
                viewBox="0 0 14.6 13.92"
              >
                <path
                  id="Icon_material-work"
                  data-name="Icon material-work"
                  d="M15.24,5.72H12.52V4.36A1.355,1.355,0,0,0,11.16,3H8.44A1.355,1.355,0,0,0,7.08,4.36V5.72H4.36A1.35,1.35,0,0,0,3.007,7.08L3,14.56a1.355,1.355,0,0,0,1.36,1.36H15.24a1.355,1.355,0,0,0,1.36-1.36V7.08A1.355,1.355,0,0,0,15.24,5.72Z"
                  transform="translate(-2.5 -2.5)"
                  fill="none"
                  stroke="#000"
                  strokeWidth="1"
                />
              </svg>
            }
            type="select"
            placeholder="Profession"
            options={servicesOptions}
            value={profession}
            onChange={(event) => onprofessionTextChangeListener(event)}
            errorMessage={professionErrorMessage}
            errorMessageVisibility={professionErrorMessageVisibility}
          />
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.18"
                height="14.744"
                viewBox="0 0 18.18 14.744"
              >
                <path
                  id="Path_9446"
                  data-name="Path 9446"
                  d="M4.718,6H18.462A1.723,1.723,0,0,1,20.18,7.718V18.026a1.723,1.723,0,0,1-1.718,1.718H4.718A1.723,1.723,0,0,1,3,18.026V7.718A1.723,1.723,0,0,1,4.718,6Z"
                  transform="translate(-2.5 -5.5)"
                  fill="none"
                  stroke="#0d0d0d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </svg>
            }
            type="email"
            placeholder="Email"
            value={email}
            onChange={(event) => onEmailTextChangeListener(event)}
            errorMessage={emailErrorMessage}
            errorMessageVisibility={emailErrorMessageVisibility}
          />
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.97"
                height="12.97"
                viewBox="0 0 12.97 12.97"
              >
                <path
                  id="Icon_ionic-ios-add"
                  data-name="Icon ionic-ios-add"
                  d="M21.127,14.642h-4.87V9.772a.807.807,0,0,0-1.615,0v4.87H9.772a.807.807,0,0,0,0,1.615h4.87v4.87a.807.807,0,1,0,1.615,0v-4.87h4.87a.807.807,0,1,0,0-1.615Z"
                  transform="translate(-8.965 -8.965)"
                />
              </svg>
            }
            type="text"
            placeholder="Years of Experience"
            value={yearsOfExperience}
            onChange={(event) => onyearsOfExperienceTextChangeListener(event)}
            errorMessage={yearsOfExperienceErrorMessage}
            errorMessageVisibility={yearsOfExperienceErrorMessageVisibility}
          />
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.125"
                height="18.124"
                viewBox="0 0 18.125 18.124"
              >
                <path
                  id="Icon_ionic-ios-call"
                  data-name="Icon ionic-ios-call"
                  d="M21.174,17.954a14.43,14.43,0,0,0-3.014-2.017c-.9-.434-1.234-.425-1.874.036-.532.385-.877.742-1.489.608A8.884,8.884,0,0,1,11.8,14.367,8.82,8.82,0,0,1,9.59,11.375c-.13-.617.228-.957.608-1.489.461-.64.474-.97.036-1.874A14.145,14.145,0,0,0,8.217,5c-.657-.657-.805-.514-1.167-.385a6.642,6.642,0,0,0-1.069.568A3.224,3.224,0,0,0,4.7,6.536c-.255.55-.55,1.574.953,4.249a23.707,23.707,0,0,0,4.168,5.559h0l0,0,0,0h0a23.8,23.8,0,0,0,5.559,4.168c2.674,1.5,3.7,1.208,4.249.953A3.169,3.169,0,0,0,20.99,20.19a6.642,6.642,0,0,0,.568-1.069C21.688,18.759,21.836,18.611,21.174,17.954Z"
                  transform="translate(-4.014 -4.033)"
                  fill="none"
                  stroke="#0d0d0d"
                  strokeWidth="1"
                />
              </svg>
            }
            type="tel"
            placeholder="Number"
            value={phoneNumber}
            onChange={(event) => onPhoneNumberTextChangeListener(event)}
            errorMessage={phoneNumberErrorMessage}
            errorMessageVisibility={phoneNumberErrorMessageVisibility}
          />{" "}
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.778"
                height="16.5"
                viewBox="0 0 14.778 16.5"
              >
                <g
                  id="Icon_feather-user"
                  data-name="Icon feather-user"
                  transform="translate(0.5 0.5)"
                >
                  <path
                    id="Path_9706"
                    data-name="Path 9706"
                    d="M19.778,27.667V25.944A3.444,3.444,0,0,0,16.333,22.5H9.444A3.444,3.444,0,0,0,6,25.944v1.722"
                    transform="translate(-6 -12.167)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_9707"
                    data-name="Path 9707"
                    d="M18.889,7.944A3.444,3.444,0,1,1,15.444,4.5,3.444,3.444,0,0,1,18.889,7.944Z"
                    transform="translate(-8.556 -4.5)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            }
            type="text"
            placeholder="CNIC"
            value={cnic}
            onChange={(event) => oncnicTextChangeListener(event)}
            errorMessage={cnicErrorMessage}
            errorMessageVisibility={cnicErrorMessageVisibility}
          />
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.861"
                height="16.401"
                viewBox="0 0 14.861 16.401"
              >
                <g
                  id="Icon_feather-lock"
                  data-name="Icon feather-lock"
                  transform="translate(0.5 0.5)"
                >
                  <path
                    id="Path_9444"
                    data-name="Path 9444"
                    d="M6.04,16.5h10.78a1.54,1.54,0,0,1,1.54,1.54v5.39a1.54,1.54,0,0,1-1.54,1.54H6.04A1.54,1.54,0,0,1,4.5,23.43V18.04A1.54,1.54,0,0,1,6.04,16.5Z"
                    transform="translate(-4.5 -9.57)"
                    fill="none"
                    stroke="#0d0d0d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_9445"
                    data-name="Path 9445"
                    d="M10.5,9.93V6.85a3.85,3.85,0,0,1,7.7,0V9.93"
                    transform="translate(-7.42 -3)"
                    fill="none"
                    stroke="#0d0d0d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            }
            type="password"
            placeholder="Password"
            value={password}
            onChange={(event) => onPasswordTextChangeListener(event)}
            errorMessage={passwordErrorMessage}
            errorMessageVisibility={passwordErrorMessageVisibility}
          />
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.861"
                height="16.401"
                viewBox="0 0 14.861 16.401"
              >
                <g
                  id="Icon_feather-lock"
                  data-name="Icon feather-lock"
                  transform="translate(0.5 0.5)"
                >
                  <path
                    id="Path_9444"
                    data-name="Path 9444"
                    d="M6.04,16.5h10.78a1.54,1.54,0,0,1,1.54,1.54v5.39a1.54,1.54,0,0,1-1.54,1.54H6.04A1.54,1.54,0,0,1,4.5,23.43V18.04A1.54,1.54,0,0,1,6.04,16.5Z"
                    transform="translate(-4.5 -9.57)"
                    fill="none"
                    stroke="#0d0d0d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_9445"
                    data-name="Path 9445"
                    d="M10.5,9.93V6.85a3.85,3.85,0,0,1,7.7,0V9.93"
                    transform="translate(-7.42 -3)"
                    fill="none"
                    stroke="#0d0d0d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            }
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(event) => onConfirmPasswordTextChangeListener(event)}
            errorMessage={confirmPasswordErrorMessage}
            errorMessageVisibility={confirmPasswordErrorMessageVisibility}
          />
          <div className="products__become__resource__form__content__upload">
            <UploadImage
              label="Experience Certificate"
              onUploadingImageCallBack={(image) => {
                if (image) setexpertCertificate((expertCertificate = image));
                else setexpertCertificate((expertCertificate = ""));
                debugger;
              }}
            />
          </div>
          <div className="products__become__resource__form__content__upload">
            <UploadImage
              label="Police Certificate"
              onUploadingImageCallBack={(image) => {
                if (image) setpoliceCertificate((policeCertificate = image));
                else setpoliceCertificate((policeCertificate = ""));
              }}
            />
          </div>
        </div>
        <div className="products__become__resource__form__text">
          <input
            type="checkbox"
            value={isChecked}
            onChange={() => {
              isChecked
                ? setIschecked((isChecked = false))
                : setIschecked((isChecked = true));
              onIsCheckedTextChangeListener(isChecked);
            }}
          />
          I agree to{" "}
          <span>
            <a href="/termsConditions">Terms and conditions</a> &{" "}
           {" "}
            <a href="/privacy">Privacy</a>
          </span>
          <div className="error__message" style={{ position: "relative" }}>
            {isCheckedErrorMessageVisibility ? isCheckedErrorMessage : ""}
          </div>
        </div>
        <button
          className="products__become__resource__form__button"
          onClick={() => {
            if (!isLoading) resourceSignUpApiCall();
          }}
        >
          {isLoading ? "Processing..." : "Join Us"}
        </button>
      </div>
      <Toaster />
    </div>
  );
}

function UploadImage({ label, onUploadingImageCallBack, setImageNull }) {
  let [upload, setUpload] = useState("");

  const onUploadImageChangeListener = (e) => {
    const fileType = e.target.files[0];
    if (fileType.type == "image/jpeg" || fileType.type == "image/png") {
      console.log("selected image", e.target.files);
      setUpload((upload = e.target.files[0]));
      onUploadingImageCallBack(upload);
      debugger;
    } else alert("Kindly Upload Image");
  };

  const onCrossButoonClick = () => {
    setUpload((upload = ""));
    onUploadingImageCallBack(upload);
  };

  useEffect(() => {
    debugger;
    if (setImageNull) {
      onCrossButoonClick();
    }
  }, []);
  return (
    <div className="popup__form__upload__wrapper">
      <div className="popup__form__upload">
        {upload === "" ? null : (
          <button
            className="popup__form__upload__btn"
            type="button"
            onClick={() => {
              onCrossButoonClick();
            }}
          >
            <X size={15} color="currentColor" />
          </button>
        )}
        <input
          type="file"
          className="popup__form__upload__input"
          onChange={(e) => onUploadImageChangeListener(e)}
          // multiple={true}
        />
        <div className="popup__form__upload__content">
          {upload === "" ? (
            <>
              <Plus size={30} color="currentcolor" /> <div>{label}</div>
            </>
          ) : (
            <img
              // src={upload}
              src={upload ? URL.createObjectURL(upload) : null}
              alt=""
              className="popup__form__upload__content__img"
            />
          )}
        </div>
      </div>
    </div>
  );
}
